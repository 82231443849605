import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountDetailsComponent } from './account-details/account-details.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { CoreModule } from 'src/app/core/core.module';
import { ChangePasswordDialogComponent } from './change-password-dialog/change-password-dialog.component';




@NgModule({
  declarations: [
    AccountDetailsComponent,
    ChangePasswordDialogComponent,
  ],
  imports: [
    CommonModule,
    CoreModule,
    SharedModule,
  ],
  exports: [
    AccountDetailsComponent,
  ],
})
export class SettingsModule { }
