import { Component, OnInit, NgZone, Input, Output, EventEmitter } from '@angular/core';
import { trigger, transition, animate, keyframes, style } from '@angular/animations';
import { MatDialog } from '@angular/material/dialog';
import { TeamOverviewComponent } from '../team-overview/team-overview.component';
import { Prediction } from 'src/app/shared/models/prediction';

@Component({
  selector: 'app-bet',
  templateUrl: './bet.component.html',
  styleUrls: ['./bet.component.scss'],
  animations: [
    trigger('wobble', [
      transition('inactive => active', animate(350, keyframes([
        style({ transform: 'translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg)', offset: .15 }),
        style({ transform: 'translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg)', offset: .30 }),
        style({ transform: 'translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg)', offset: .45 }),
        style({ transform: 'translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg)', offset: .60 }),
        style({ transform: 'translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg)', offset: .75 }),
        style({ transform: 'none', offset: 1 }),
      ]))),
    ])
  ]
})
export class BetComponent implements OnInit {

  public wobbleState: string;
  public showProbability = false;

  @Input() showResults = false;
  @Input() prediction: Prediction;
  @Input() boost2 = false;
  @Output() crownChange = new EventEmitter<number>();
  @Output() betChange = new EventEmitter<number>();

  constructor(
    public zone: NgZone,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
  }

  crownClick() {
    if (!this.showResults) {
      this.crownChange.emit(this.prediction.id);
      this.prediction.matchStar = !this.prediction.matchStar;
      if (this.prediction.matchStar) {
        setTimeout(() => {
          this.wobbleState = 'active';
        }, 100);
      }
    }
  }


  reset() {
    this.zone.run(() => {
      this.wobbleState = 'inactive';
    });
  }

  showProbabilityClick() {
    this.showProbability = !this.showProbability;
  }

  showTeam(id: number) {
    this.dialog.open(TeamOverviewComponent, {
      width: '96%', maxWidth: '600px',
      data: { id }
    });
  }

  teamClick(val: number) {
    this.prediction.winSelection = val;
    this.betChange.emit(val);
  }

  goalClick(val: number) {
    this.prediction.goalsSelection = val;
  }

}
