<div style="padding-bottom: 60%;">
    <h3>{{'PICK_YOUR_TEAM_NAME' | translate }}</h3>
    <form [formGroup]="formGroup">
        <app-input-container>
            <input fxFlex
                   placeholder="{{'TEAM_NAME' | translate }}"
                   formControlName="teamName"
                   required>
            <mat-error *ngIf="submitted && formGroup.get('teamName').hasError('required')">
                {{'TEAM_NAME_IS_REQUIRED' | translate }}
            </mat-error>
        </app-input-container>
    </form>
</div>

<div fxLayout="column"
     fxLayoutGap="16px">
    <button mat-raised-button
            color="warn"
            (click)="submit()">
        {{'START_GAME' | translate }}
    </button>
</div>