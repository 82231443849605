<form [formGroup]="formGroup">
    <app-input-container>
        <input fxFlex
               type="text"
               placeholder="{{'EMAIL' | translate }}"
               formControlName="email"
               required>
        <mat-error *ngIf="submitted && formGroup.get('email').hasError('required')">
            {{'EMAIL_IS_REQUIRED' | translate }}
        </mat-error>
    </app-input-container>
</form>

<div fxLayout="column"
     fxLayoutGap="8px">
    <button mat-raised-button
            color="warn"
            (click)="submit()">
        {{'CONFIRM' | translate }}
    </button>
    <button mat-raised-button
            routerLink="/login">
        {{'SIGN_IS_AGAIN' | translate }}
    </button>
</div>