<div class="primary-bg">
    <div mat-dialog-title
         fxLayout="row"
         fxLayoutAlign="space-between center"
         style="color: white; padding: 8px;">
        <div>
            {{'CHANGE_PASSWORD' | translate }}
        </div>
        <button mat-icon-button
                (click)="clickCancel()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <div style="padding: 16px;">
        <form [formGroup]="formGroup">
            <app-input-container style="margin-top: 8px;">
                <input fxFlex
                       type="password"
                       placeholder="{{'NEW_PASSWORD' | translate }} "
                       formControlName="newPassword"
                       autocomplete="new-password"
                       required>
                <mat-error *ngIf="formGroup.get('newPassword').hasError('required')">
                    {{'NEW_PASSWORD_IS_REQUIRED' | translate }}
                </mat-error>
            </app-input-container>

            <app-input-container style="margin-bottom: 10%; margin-top: 16px;">
                <input fxFlex
                       type="password"
                       placeholder="{{'PASSWORD_AGAIN' | translate }}"
                       formControlName="newPasswordAgain"
                       autocomplete="new-password"
                       required>
                <mat-error *ngIf="
                formGroup.get('newPasswordAgain').hasError('mustMatch') 
                || 
                (formGroup.get('newPasswordAgain').hasError('required'))">
                    {{'PASSWORD_DO_NOT_MATCH' | translate }}
                </mat-error>
            </app-input-container>
        </form>
    </div>
    <div fxLayout="column">
        <button mat-raised-button
                color="warn"
                (click)="submit()">
            {{'CONFIRM' | translate }}
        </button>
    </div>
</div>