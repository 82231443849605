import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';

import { AuthService } from './auth.service';
import { map, catchError, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {

  constructor(
    private authService: AuthService,
    private router: Router,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const url: string = state.url;
    return this.checkLoginStatus(url);
  }

  checkLoginStatus(url: string): Observable<boolean> {
    if (this.authService.user) {
      return of(true);
    } else {
      return this.authService.getMyData()
        .pipe(
          map(data => {
            return true;
          }),
          catchError(
            () => {
              this.router.navigate(['/login']);
              return of(false);
            }
          )
        );
    }
  }
}
