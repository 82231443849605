<div class="primary-bg"
     style="padding: 24px 16px;">

    <h2>Notendaskilmálar</h2>
    <p>Þessi vefsíða er í eigu og rekin af 0101 ehf, kt. 540918-0660, Grandagarði 9, 101 Reykjavík, Ísland.</p>
    <p>Öllum fyrirspurnum varðandi leikinn eða þessa skilmála skal beina til 0101 ehf í gegnum EMAIL</p>
    <h3>Skráðir notendur</h3>
    <ul>
        <li>Til að spila leikinn þarf að fylla út skráningarform á síðunni og smella á hlekk sem staðfestir uppgefið
            netfang. Ætlast er til að notendur gefi réttar upplýsingar við skráningu. Ef mistök eru gerð við skráningu
            er hægt að breyta upplýsingum í stillingum þegar notandi er innskráður.</li>
        <li>Aðeins er leyfður einn reikningur á hvern einstakling. Ef grunur er um að einstaklingur haldi úti mörgum
            reikningum má 0101 loka þeim reikningum og óska eftir frekari upplýsingum frá notenda. </li>
        <li>Skráðir notendur samþykja að 0101 má senda þeim tölvupóst tengdan þessum leik og samstarfsaðilum þessa
            leiks. Ef notandi vill ekki fá slíka tölvupósta er hægt að afskrá sig frá póstsendingum neðst í hverjum
            sendum tölvupóst.</li>
        <li>Ef notendanafn telst ósmekklegt eða niðrandi hefur 0101 heimild til að breyta notendanafni og/eða loka
            viðkomandi reikning.</li>
    </ul>
    <h3>Vinningar</h3>
    <ul>
        <li>Vinningar fyrir stigahæstu notendur og nánari reglur eru birtar á síðunni <a style="color: #EC2E55;"
               routerLink="/main/winners"
               target="_self">Vinningar</a> á vefsíðunni.</li>
        <li>Ef vinningshafi verður uppvís á að vera skráður með marga reikninga á síðunni verður vinning útdeild í næsta
            sæti fyrir neðan.</li>
        <li>Í einhverjum tilfellum geta verið aldurstakmörk á vinningum, ef notandi hefur ekki náð aldri er vinning
            útdeilt til næsta sætis.</li>
        <li>0101 getur óskað eftir því að notandi beri kennsl á sér áður en vinningar eru afhentir.</li>
        <li>Í flestum tilfellum verða vinningar útvegaðir af þriðja aðila og verða í formi vara, þjónustu eða upplifana.
            0101 ber enga ábyrgð á upplifun eða atvikum sem eiga sér stað þegar þessar vörur, þjónustur eða upplifanir
            eru nýttar.</li>
    </ul>
    <h3>Leikurinn</h3>
    <ul>
        <li>Regulur leiksin má finna undir <a style="color: #EC2E55;"
               routerLink="/main/rules"
               target="_self">reglur</a> á vefsíðunni.</li>
        <li>Notast er við þriðja aðila gagnaveitu fyrir upplýsingar um úrslit o.fl tengt leiknum, ef talið er að mistök
            séu gerð getur 0101 breytt þeim upplýsingum.</li>
        <li>Ef notandi heldur úti fleiri en einum reikning eða reynir að svindla með einum eða öðrum hætti má 0101 loka
            reikning hans og útiloka frá keppni í þessum leik.</li>
    </ul>
    <h3>Persónuvernd</h3>
    <ul>
        <li>Persónuverndarstefnu 0101 getur þú séð undir <a style="color: #EC2E55;"
               routerLink="/main/privacy"
               target="_self">persónuverndarstefna</a> á vefsíðunni.</li>
    </ul>

</div>