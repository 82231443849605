<form [formGroup]="formGroup">
    <app-input-container>
        <input fxFlex
               type="text"
               placeholder="{{'NAME' | translate }}"
               formControlName="name"
               required>
        <mat-error *ngIf="submitted && formGroup.get('name').hasError('required')">
            {{'NAME_IS_REQUIRED' | translate }}
        </mat-error>
    </app-input-container>

    <app-input-container>
        <input fxFlex
               type="text"
               placeholder="{{'EMAIL' | translate }}"
               formControlName="email"
               required>
        <mat-error *ngIf="submitted && formGroup.get('email').hasError('required')">
            {{'EMAIL_IS_REQUIRED' | translate }}
        </mat-error>
        <mat-error *ngIf="submitted && formGroup.get('email').hasError('email')">
            {{'EMAIL_IS_INCORECT' | translate }}
        </mat-error>
    </app-input-container>

    <div fxLayout="row"
         fxLayoutGap="4px">
        <app-input-container fxFlex>
            <input fxFlex
                   formControlName="dob"
                   placeholder="{{'DATA_OF_BIRTH' | translate }}"
                   [matDatepicker]="picker"
                   [max]="maxDate">
            <mat-datepicker #picker></mat-datepicker>
            <mat-error *ngIf="submitted && formGroup.get('dob').hasError('required')">
                {{'DATA_OF_BIRTH_REQUIRED' | translate }}
            </mat-error>
        </app-input-container>
        <mat-datepicker-toggle matSuffix
                               [for]="picker"
                               style="
                               color: white;
                               position: relative;
                               top: -4px;">
        </mat-datepicker-toggle>
    </div>

    <app-input-container class="pointer"
                         fxLayout="row"
                         fxLayoutAlign="space-between center">
        <mat-select formControlName="idCountry"
                    placeholder="{{'COUNTRY' | translate }}"
                    required>
            <mat-option *ngFor="let country of dataManager.countries"
                        [value]="country.id">
                {{country.country_name}}
            </mat-option>
        </mat-select>
        <mat-error *ngIf="submitted && formGroup.get('idCountry').hasError('required')">
            {{'COUNTRY_IS_REQUIRED' | translate }}
        </mat-error>
    </app-input-container>

    <app-input-container>
        <input fxFlex
               type="password"
               placeholder="{{'PASSWORD' | translate }}"
               formControlName="password"
               autocomplete="new-password"
               required>
        <mat-error *ngIf="submitted && formGroup.get('password').hasError('required')">
            {{'PASSWORD_IS_REQUIRED' | translate }}
        </mat-error>
    </app-input-container>

    <app-input-container>
        <input fxFlex
               type="password"
               placeholder="{{'RETYPE_PASSWORD' | translate }}"
               formControlName="confirmPassword"
               autocomplete="new-password"
               required>
        <mat-error *ngIf="
                        formGroup.get('confirmPassword').hasError('mustMatch') 
                        || 
                        (submitted && formGroup.get('confirmPassword').hasError('required'))">
            {{'PASSWORD_DO_NOT_MATCH' | translate }}
        </mat-error>
    </app-input-container>
</form>

<div fxLayout="column"
     fxLayoutGap="8px">
    <button mat-raised-button
            color="warn"
            (click)="submit()">
        {{'SIGN_UP' | translate }}
    </button>

    <button mat-button
            routerLink="/login">
        {{'ALREADY_HAVE_ACCOUNT' | translate }}
    </button>
</div>