import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Sponsors } from 'src/app/shared/models/sponsor';
import { DataManagerService } from 'src/app/core/services/data-manager.service';

@Component({
  selector: 'app-perfect-pick',
  templateUrl: './perfect-pick.component.html',
  styleUrls: ['./perfect-pick.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PerfectPickComponent implements OnInit {

  public sponsors: Sponsors = {} as Sponsors;

  constructor(
    public dataManager: DataManagerService
  ) {
    this.getBettingSponsors();
  }

  ngOnInit(): void {
  }

  getBettingSponsors() {
    this.dataManager.getbettingsponsors().subscribe(sponsors => this.sponsors = sponsors);
  }

}
