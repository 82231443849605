import { Component, OnInit } from '@angular/core';
import { League } from 'src/app/shared/models/league';
import { DataManagerService } from 'src/app/core/services/data-manager.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-leagues-list',
  templateUrl: './leagues-list.component.html',
  styleUrls: ['./leagues-list.component.scss']
})
export class LeaguesListComponent implements OnInit {

  public columns = ['position', 'name', 'userName'];
  public league: League;

  constructor(
    public dataManager: DataManagerService,
  ) {
    this.getLeagues();
  }

  ngOnInit(): void {
  }

  getLeagues() {
    this.dataManager.getLeagues().subscribe(league => {
      this.league = league;
    });
  }

}
