import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { DataManagerService } from './services/data-manager.service';
import { HttpTokenInterceptor } from './interceptors/token.interceptor';
import { HttpErrorInterceptor } from './interceptors/error.interceptor';
import { HttpLoaderInterceptor } from './interceptors/loader.interceptor';

export const HttpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: HttpTokenInterceptor, multi: true },
];
export const HttpErrorInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
];
export const HttpLoaderInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: HttpLoaderInterceptor, multi: true },
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
  ],
  providers: [
    DataManagerService,
    HttpInterceptorProviders,
    HttpErrorInterceptorProviders,
    HttpLoaderInterceptorProviders,
  ]
})
export class CoreModule { }
