<div class="primary-bg">
    <div fxLayout="row"
         fxLayoutAlign="center center"
         style="padding: 24px;">
        {{'CONTACT_US' | translate }}
    </div>
    <div style="padding: 24px;">
        <p>Ef þú hefur einhverjar spurningar vinsamlegast sendu okkur tölvupóst á netfangið <a style="color: #EC2E55;"
               href="mailto:pikkid@0101.is">pikkid@0101.is</a></p>

        <p>Einnig getur þú sent okkur skilaboð <a style="color: #EC2E55;" target="_blank"
            href="https://www.facebook.com/Pikkid1x2">Facebook</a> síðu leiksins.</p>
    </div>
</div>
<div style="margin-bottom: 89%;">
</div>