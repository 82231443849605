import { Component, OnInit, Input } from '@angular/core';
import { DataManagerService } from 'src/app/core/services/data-manager.service';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { LeaderboardItem } from 'src/app/shared/models/leaderboardItem';
import { Router } from '@angular/router';
import { LeagueDetail } from 'src/app/shared/models/league-detail';

@Component({
  selector: 'app-leaderboard-list',
  templateUrl: './leaderboard-list.component.html',
  styleUrls: ['./leaderboard-list.component.scss']
})
export class LeaderboardListComponent implements OnInit {

  @Input() leaderboardItems: LeaderboardItem[] = [];

  constructor(
    public dataManager: DataManagerService,
    public dialog: MatDialog,
    private toastr: ToastrService,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
  }

  showResults(userId: string, teamName: string) {
    this.router.navigate(['/main/result'], { queryParams: { userId, teamName } });
  }

}
