import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LeaguesComponent } from './leagues.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { LeaguesListComponent } from './leagues-list/leagues-list.component';
import { LeagueJoinComponent } from './league-join/league-join.component';
import { LeaguesGenerateComponent } from './leagues-generate/leagues-generate.component';
import { LeagueShareComponent } from './league-share/league-share.component';
import { CoreModule } from 'src/app/core/core.module';
import { LeaderboardModule } from '../leaderboard/leaderboard.module';
import { LeagueComponent } from './league/league.component';



@NgModule({
  declarations: [
    LeaguesComponent,
    LeaguesListComponent,
    LeagueJoinComponent,
    LeaguesGenerateComponent,
    LeagueShareComponent,
    LeagueComponent,
  ],
  imports: [
    CommonModule,
    CoreModule,
    SharedModule,
    LeaderboardModule,
  ],
  exports: [
    LeaguesListComponent,
    LeagueJoinComponent,
    LeaguesGenerateComponent,
    LeagueComponent,
  ],
})
export class LeaguesModule { }
