import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LoginComponent } from './login/login.component';
import { SharedModule } from '../shared/shared.module';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { RegisterComponent } from './register/register.component';
import { AgreementComponent } from './agreement/agreement.component';
import { TeamNameComponent } from './team-name/team-name.component';
import { AuthComponent } from './auth.component';
import { CoreModule } from '../core/core.module';



@NgModule({
  declarations: [
    AuthComponent,
    LoginComponent,
    ChangePasswordComponent,
    ForgotPasswordComponent,
    RegisterComponent,
    AgreementComponent,
    TeamNameComponent,
  ],
  imports: [
    CommonModule,
    CoreModule,
    SharedModule,
  ]
})
export class AuthModule { }
