<div>
    <mat-table [dataSource]="league"
               class="primary-bg mat-elevation-z1">

        <ng-container matColumnDef="position">
            <mat-header-cell *matHeaderCellDef
                             style="color: white;"
                             fxLayoutAlign="start center"
                             fxFlex>
                {{'POSITION' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let league"
                      style="background-color: white;"
                      fxLayoutAlign="start center"
                      fxFlex>
                {{league.ord}}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef
                             style="color: white;"
                             fxLayoutAlign="center center"
                             fxFlex>
                {{'LEAGUES' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let league"
                      style="background-color: white;"
                      fxLayoutAlign="center center"
                      fxFlex>
                {{league.name}}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="userName">
            <mat-header-cell *matHeaderCellDef
                             style="color: white;"
                             fxLayoutAlign="end center"
                             fxFlex>
                {{'PLAYER_NAME' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let league"
                      style="background-color: white;"
                      fxLayoutAlign="end center"
                      fxFlex>
                {{league.userName}}
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: columns;"
                 [routerLink]="['/main/leagues', row.id]"
                 [queryParams]="{ name: row.name, description: row.description }"></mat-row>
    </mat-table>
</div>