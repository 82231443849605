<div class="content"
     fxLayout="column"
     fxLayoutGap="16px">
    <div fxLayout="column"
         fxLayoutGap="8px">
        <h3 style="text-align: center;">{{'GAME' | translate }}</h3>
        <button mat-flat-button
                routerLink="/main/leaderboard"
                (click)="hide()">
            {{'LEADERBOARD' | translate | uppercase }}
        </button>
        <button mat-flat-button
                routerLink="/main/leagues"
                (click)="hide()">
            {{'LEAGUES' | translate | uppercase }}
        </button>
        <button mat-flat-button
                routerLink="/main/winners"
                (click)="hide()">
            {{'WINNERS' | translate | uppercase }}
        </button>
        <!--<button mat-flat-button
                routerLink="/main/perfect-pick"
                (click)="hide()">
            {{'PERFECT_PICK' | translate | uppercase }}
        </button>-->
        <button mat-flat-button
                routerLink="/main/rules"
                (click)="hide()">
            {{'RULES' | translate | uppercase }}
        </button>
    </div>
    <div fxLayout="column"
         fxLayoutGap="8px">
        <h3 style="text-align: center;">{{'SETTINGS' | translate }}</h3>
        <button mat-flat-button
                routerLink="/main/accountdetails"
                (click)="hide()">
            {{'ACCOUNT_DETAILS' | translate | uppercase }}
        </button>
    </div>
    <div fxLayout="column"
         fxLayoutGap="8px">
        <h3 style="text-align: center;">{{'OTHER' | translate | uppercase }}</h3>
        <button mat-flat-button
                routerLink="/main/contact"
                (click)="hide()">
            {{'CONTACT_US' | translate | uppercase }}
        </button>
        <button mat-flat-button
                routerLink="/main/terms"
                (click)="hide()">
            {{'TERMS_&_CONDITIONS' | translate | uppercase }}
        </button>
        <button mat-flat-button
                routerLink="/main/privacy"
                (click)="hide()">
            {{'PRIVACY_POLICY' | translate | uppercase }}
        </button>
    </div>

    <div style="height: 2px; background-color: white;"></div>

    <div fxLayoutAlign="center center">
        <button mat-raised-button
                fxFlex
                color="warn"
                (click)="signOut()">
            {{'SIGN_OUT' | translate | uppercase }}
        </button>
    </div>
</div>