<div class="primary-bg"
     style="padding: 8px 16px;">
     <div fxLayout="row"
          fxLayoutAlign="center center"
          style="padding: 16px;">
          <span>{{name}}</span>
     </div>
     <div fxLayout="row"
          fxLayoutAlign="center center"
          style="padding: 8px;">
          <span>{{description}}</span>
     </div>
     <div style="margin-bottom: 40%;">
          <div class="warn-bg"
               fxLayout="row"
               fxLayoutAlign="center center"
               style="height: 50px;">
               {{'TABLES' | translate }}
          </div>
          <app-leaderboard-list [leaderboardItems]="leaderboardItems">
          </app-leaderboard-list>
     </div>
</div>