<form [formGroup]="formGroup">
    <app-input-container>
        <input fxFlex
               type="password"
               placeholder="{{'NEW_PASSWORD' | translate }}"
               formControlName="password"
               autocomplete="new-password"
               required>
        <mat-error *ngIf="submitted && formGroup.get('password').hasError('required')">
            {{'PASSWORD_IS_REQUIRED' | translate }}
        </mat-error>
    </app-input-container>

    <app-input-container>
        <input fxFlex
               type="password"
               placeholder="{{'PASSWORD_AGAIN' | translate }}"
               formControlName="passwordAgain"
               autocomplete="new-password"
               required>
        <mat-error *ngIf="
                formGroup.get('passwordAgain').hasError('mustMatch') 
                || 
                (submitted && formGroup.get('passwordAgain').hasError('required'))">
            {{'PASSWORD_DO_NOT_MATCH' | translate }}
        </mat-error>
    </app-input-container>
</form>

<div fxLayout="column">
    <button mat-raised-button
            color="warn"
            (click)="submit()">
        {{'CONFIRM' | translate }}
    </button>
</div>