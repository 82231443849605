import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { DataManagerService } from 'src/app/core/services/data-manager.service';
import { MatDialogRef } from '@angular/material/dialog';
import { AccountDetailsComponent } from '../account-details/account-details.component';
import { MustMatch } from 'src/app/shared/validator/must-match.validator';

@Component({
  selector: 'app-change-password-dialog',
  templateUrl: './change-password-dialog.component.html',
  styleUrls: ['./change-password-dialog.component.scss']
})
export class ChangePasswordDialogComponent implements OnInit {

  public formGroup: FormGroup = this.fb.group({
    newPassword: [
      '', Validators.compose([Validators.required])
    ],
    newPasswordAgain: [
      '', Validators.compose([Validators.required])
    ]
  }, {
    validator: MustMatch('newPassword', 'newPasswordAgain')
  });

  constructor(
    private fb: FormBuilder,
    public dataManager: DataManagerService,
    public dialogRef: MatDialogRef<AccountDetailsComponent>,
  ) { }

  ngOnInit(): void {
  }

  clickCancel(): void {
    this.dialogRef.close();
  }

  submit() {
    if (!this.formGroup.invalid) {
      this.dialogRef.close(this.formGroup.value);
    }
  }
}
