import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, from, BehaviorSubject, forkJoin } from 'rxjs';
import { tap, map, switchMap } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { DataManagerService } from '../core/services/data-manager.service';

export const ACCESSTOKEN_STORAGE_KEY = 'fb1x2-token';
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public token: string;
  public user: any;
  public gameWeeks: [];

  constructor(
    private http: HttpClient,
    private router: Router,
  ) {
    this.token = window.localStorage.getItem(ACCESSTOKEN_STORAGE_KEY);
  }

  login(data: any) {
    data = {
      ...data,
      grant_type: 'password',
      client_id: 2,
      client_secret: 'zKE4Ilr1BaCfvCqDlsGAhCgRvxAAB4ikGDaMcYfV'
    };
    return this.http.post<any>(environment.loginUrl, data)
      .pipe(
        tap(res => {
          window.localStorage.setItem(ACCESSTOKEN_STORAGE_KEY, res.access_token);
          this.token = res.access_token;
        }),
        switchMap(res => this.getMyData()),
      );
  }

  public getMyData() {
    return this.http.get<any>(`${environment.apiUrl}/me`)
      .pipe(
        tap(data => {
          this.user = data.user;
        }),
        // switchMap(() => this.dataManager.getGameweeks()),
        // tap(gameWeeks => this.gameWeeks = gameWeeks),
        // map(() => this.user)
      );
  }

  public logout() {
    window.localStorage.removeItem(ACCESSTOKEN_STORAGE_KEY);
    this.token = undefined;
    this.user = undefined;
    this.router.navigate(['/login']);
  }

}
