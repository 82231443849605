<div class="primary-bg"
     style="padding: 32px 8px; text-align: center;">

    <div style="margin-top: 15%;">
        <h2>Deildu þessum kóða með vinum þínum</h2>
    </div>

    <div fxLayout="column"
         fxLayoutGap="12px"
         style="padding: 25px;">
        <p>Þeir skrá kóðann svo inn á sínum aðgang og eru þá komnir í deildina þína.</p>
        <p>Athugið að stig i deild telja frá skráningardegi í deildina, því er mikilvægt að allir skrái sig í deildina á
            réttum tíma.</p>
        <p style="text-align: center; font-size: 2rem"
           (click)="copyToClip()">{{code}}</p>
    </div>
    <div fxLayout="column"
         class="container"
         style="margin-top: 30%;margin-bottom: 20%;">
        <button mat-raised-button
                color="accent"
                (click)="shareCode()">
            Afrita kóða
        </button>
    </div>
</div>