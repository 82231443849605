import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { DataManagerService } from 'src/app/core/services/data-manager.service';
import { MatDialog } from '@angular/material/dialog';
import { ChangePasswordDialogComponent } from '../change-password-dialog/change-password-dialog.component';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/auth/auth.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-account-details',
  templateUrl: './account-details.component.html',
  styleUrls: ['./account-details.component.scss']
})
export class AccountDetailsComponent implements OnInit {

  public submitted = false;
  public countries = this.dataManager.countries;
  public maxDate = new Date(2004, 0, 31);

  public formGroup = this.fb.group({
    name: ['', Validators.required],
    email: ['', Validators.required],
    idCountry: ['', Validators.required],
    teamName: ['', Validators.required],
    dob: ['', Validators.required],
    emailNotifications: [false, Validators.required],
  });

  constructor(
    public dataManager: DataManagerService,
    public dialog: MatDialog,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private authService: AuthService,
    private translate: TranslateService,
  ) {
    const user = { ...this.authService.user };
    user.dob = new Date(user.dob);
    user.emailNotifications = user.emailNotifications === '1' ? true : false;
    this.formGroup.patchValue(user);
  }

  ngOnInit(): void {
  }

  changePassword() {
    this.dialog.open(ChangePasswordDialogComponent, {
      width: '96%', maxWidth: '400px'
    })
      .afterClosed()
      .subscribe(data => {
        if (data) {
          this.dataManager.postNewPassword(data)
            .subscribe(
              () => {
                this.translate.get('YOUR_PASSWORD_HAS_BEEN_CHANGED_SUCCESSFULLY').subscribe(trans => {
                  this.toastr.show(trans);
                });
              });
        }
      });
  }

  submit() {
    const user = {...this.formGroup.value};
    user.emailNotifications = user.emailNotifications ? '1' : '0';
    user.dob = user.dob.getTime();
    this.dataManager.postMe2(user)
      .subscribe(res => {
        this.toastr.show('Done');
      });
  }

}
