<div fxLayout="column"
     fxLayoutGap="8px">

    <button mat-raised-button
            routerLink="/main/rules">
        {{'HOW_IT_WORKS' | translate | uppercase }} ?
    </button>

    <button mat-raised-button
            routerLink="/main/terms">
        {{'TERMS_&_CONDITIONS' | translate }}
    </button>
    <button mat-raised-button
            routerLink="/main/privacy">
        {{'PRIVACY_POLICY' | translate }}
    </button>

    <button mat-raised-button
            color='accent'
            style="color: white;"
            (click)="agreeTerms()">
        {{'AGREE' | translate }}
    </button>
</div>