import { Component, OnInit } from '@angular/core';
import { DataManagerService } from 'src/app/core/services/data-manager.service';
import { ActivatedRoute } from '@angular/router';
import { LeagueDetail } from 'src/app/shared/models/league-detail';
import { League } from 'src/app/shared/models/league';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-league',
  templateUrl: './league.component.html',
  styleUrls: ['./league.component.scss']
})
export class LeagueComponent implements OnInit {

  public leaderboardItems: LeagueDetail[];
  private id: number;
  public name = '';
  public description = '';

  constructor(
    public dataManager: DataManagerService,
    private route: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
    this.id = Number(this.route.snapshot.paramMap.get('id'));
    this.getLeague();
    this.route
    .queryParamMap
    .pipe(
      tap(params => {
        this.name = params.get('name');
        this.description = params.get('description');
      })
    )
    .subscribe();
  }

  getLeague() {
    this.dataManager.getLeague(this.id).subscribe(leaderboardItems => {
      this.leaderboardItems = leaderboardItems;
    });
  }

}

