<app-score></app-score>
<div fxLayout="row">
    <button class="tab-button"
            fxFlex
            mat-raised-button
            [color]="tab === 0 ? 'primary': 'warn'"
            (click)="changeTab(0)">
        {{'TICKET' | translate | uppercase }}
    </button>
    <button class="tab-button"
            fxFlex
            mat-raised-button
            [color]="tab === 1 ? 'primary': 'warn'"
            (click)="changeTab(1)">
        {{'RESULTS' | translate | uppercase }}
    </button>
</div>

<app-ticket *ngIf="tab===0"></app-ticket>
<app-results *ngIf="tab===1"></app-results>