<div class="content">
    <div class="mat-elevation-z8"
         style="padding: 32px 16px; text-align: center;">
        <div fxLayout="row"
             style="padding: 12px; margin-top: 2%;">
            <mat-form-field class="fantasy-select">
                <mat-select [value]="gameweeks[gameweeks.length - 1]?.id">
                    <mat-option [value]="0"
                                (click)="getSponsors()">
                        {{'OVERALL' | translate }}
                    </mat-option>
                    <mat-option *ngFor="let gameweek of gameweeks"
                                [value]="gameweek.id"
                                (click)="getSponsors(gameweek.id)">
                        {{gameweek.gameweekTitle}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div style="background-color: white;">
            <div class="primary-bg"
                 style="height: 60px; margin-top: 16px;"
                 fxLayoutAlign="center center">
                <span>{{sponsors.title}}</span>
            </div>
            <img [src]="sponsors.imageSrc"
                 style="width: 100%;">
        </div>
        <div class="description">
            <div [innerHTML]="sponsors.description"></div>
        </div>
    </div>
</div>