<div class="content">
    <div class="primary-bg"
         style="padding: 32px 8px; text-align: center;">

        <div style="padding: 0px 24px;">
            <div style="margin-top: 25%;">
                <p>Afritaðu kóðann sem þú fékkst til að skrá þig í deildina.</p>
            </div>
            <div style="margin-top: 20%;">
                <p>Athugið að stig i deild telja frá skráningardegi í deildina, því er mikilvægt að allir skrái sig í
                    deildina á réttum tíma.</p>
            </div>
        </div>

        <div fxLayout="column"
             class="container"
             fxLayoutGap="36px"
             style="margin-top: 30%; margin-bottom: 7%;">

            <app-input-container>
                <input fxFlex
                       type="text"
                       placeholder="Settu kóðann hér"
                       [(ngModel)]="code"
                       required>
            </app-input-container>

            <button mat-raised-button
                    color="accent"
                    (click)="joinLeague()">
                Skrá sig í deild
            </button>

        </div>
    </div>
</div>