import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { DataManagerService } from 'src/app/core/services/data-manager.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  public submitted = false;
  public showSpinner = false;
  public passwordToken = '';

  public formGroup = this.fb.group({
    password: ['', Validators.required],
    passwordAgain: ['', Validators.required],
  });

  constructor(
    private fb: FormBuilder,
    public dataManager: DataManagerService,
    private router: Router,
    private toastr: ToastrService,
    private translate: TranslateService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.passwordToken = this.route.snapshot.paramMap.get('token');
  }

  submit() {
    this.submitted = true;
    if (!this.formGroup.invalid) {
      this.showSpinner = true;
      this.dataManager.resetPassword({ ...this.formGroup.value, passwordToken: this.passwordToken })
        .subscribe(
          (data) => {
            this.showSpinner = false;
            this.translate.get('YOUR_PASSWORD_HAS_BEEN_CHANGED_SUCCESSFULLY').subscribe(trans => {
              this.toastr.show(trans);
            });
            this.router.navigate(['login']);
          }, e => {
            this.showSpinner = false;
          }
        );
    }
  }
}
