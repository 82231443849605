import { Injectable, OnInit } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

import { environment } from 'src/environments/environment';
import { removeEmpty } from 'src/app/shared/helpers/helpers';
import { filter, map } from 'rxjs/operators';
import { Ticket } from 'src/app/shared/models/ticket';
import { Gameweek } from 'src/app/shared/models/gameweek';

@Injectable()
export class DataManagerService {

  public countries = [];

  constructor(
    private http: HttpClient,
    private toastr: ToastrService,
  ) {
    this.getCountries().subscribe(countries => {
      this.countries = countries;
    });
  }

  getNewToken() {
    return this.http.get<any>(`${environment.apiUrl}/switchuser`);
  }

  register(data: any) {
    return this.http.post<any>(`${environment.apiUrl}/register`, data);
  }

  forgotPassword(data: any) {
    return this.http.post<any>(`${environment.apiUrl}/forgotpassword`, data);
  }

  resetPassword(data: any) {
    return this.http.post<any>(`${environment.apiUrl}/resetpassword`, data);
  }

  postNewPassword(data: any) {
    return this.http.post<any>(`${environment.apiUrl}/password`, data);
  }

  getMe() {
    return this.http.get<any>(`${environment.apiUrl}/me`);
  }

  postMe(name: string, agreement: boolean, teamName: string) {
    return this.http.post<any>(`${environment.apiUrl}/me`, removeEmpty({ name, agreement, teamName }));
  }

  postMe2(data: any) {
    return this.http.post<any>(`${environment.apiUrl}/me`, removeEmpty(data));
  }

  changePassword(data) {
    return this.http.post<any>(`${environment.apiUrl}/password`, data);

  }

  getCountries() {
    return this.http.get<any>(`${environment.apiUrl}/countries`);
  }

  getHome() {
    return this.http.get<any>(`${environment.apiUrl}/home`);
  }

  getMatches(params?) {
    return this.http.get<any>(`${environment.apiUrl}/matches`, { params });
  }

  getStatisticsSortByList() {
    return this.http.get<any>(`${environment.apiUrl}/statistics/sortby`);
  }

  getStatistics(params?) {
    return this.http.get<any>(`${environment.apiUrl}/statistics`, { params });
  }

  getLeaderboard(gameWeeksId?, pageIndex?: number, pageSize?: number) {
    let params = new HttpParams();
    if (gameWeeksId) {
      params = params.set('gameWeeksId', gameWeeksId);
    }
    if (length) {
      params = params.set('length', length.toString());
    }
    if (pageIndex) {
      params = params.set('pageIndex', pageIndex.toString());
    }
    if (pageSize) {
      params = params.set('pageSize', pageSize.toString());
    }
    return this.http.get<any>(`${environment.apiUrl}/leaderboard`, { params });
  }

  getLeaderboardOverall() {
    return this.http.get<any>(`${environment.apiUrl}/leaderboard/overall`);
  }

  getLeaderboardList(params?) {
    return this.http.get<any>(`${environment.apiUrl}/leaderboard/table`, { params });
  }

  getLeaderboardDetails(id: number, gameWeeksId) {
    if (gameWeeksId) {
      return this.http.get<any>(`${environment.apiUrl}/leaderboard/table/${id}`, { params: { gameWeeksId } });
    } else {
      return this.http.get<any>(`${environment.apiUrl}/leaderboard/table/${id}`);
    }
  }

  getLeagues() {
    return this.http.get<any>(`${environment.apiUrl}/leagues`);
  }

  postLeagues(name: string, description: string) {
    return this.http.post<any>(`${environment.apiUrl}/leagues`, { name, description });
  }

  getLeague(id: number) {
    return this.http.get<any>(`${environment.apiUrl}/leagues/${id}`);
  }

  joinLeague(code: string) {
    return this.http.post<any>(`${environment.apiUrl}/leagues/join`, { code });
  }

  getTicket(gameWeeksId?) {
    let params = new HttpParams();
    if (gameWeeksId) {
      params = params.set('gameWeeksId', gameWeeksId);
    }
    return this.http.get<any>(`${environment.apiUrl}/ticket`, { params });
  }

  postTicket(ticket: Ticket) {
    return this.http.post<any>(`${environment.apiUrl}/ticket`, ticket);
  }

  getStandings() {
    return this.http.get<any>(`${environment.apiUrl}/standings`);
  }

  getSponsors(gameWeeksId?) {
    return this.http.get<any>(`${environment.apiUrl}/sponsors`, { params: removeEmpty({ gameWeeksId }) });
  }

  getGameweeks(includeActive?) {
    return this.http.get<Gameweek[]>(`${environment.apiUrl}/gameweeks`, { params: removeEmpty({ includeActive }) });
  }

  getResults(userId, gameWeeksId?) {
    let params = new HttpParams();
    if (gameWeeksId) {
      params = params.set('gameWeeksId', gameWeeksId);
    }
    if (userId) {
      params = params.set('userId', userId);
    }

    return this.http.get<any>(`${environment.apiUrl}/results`, { params });
  }

  getTeam(id: any) {
    return this.http.get<any>(`${environment.apiUrl}/sportteams`, { params: { id } });
  }

  getbettingsponsors() {
    return this.http.get<any>(`${environment.apiUrl}/bettingsponsors`);
  }

  postlogtippa() {
    return this.http.post<any>(`${environment.apiUrl}/logtippa`, {});
  }
}
