import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { DataManagerService } from '../core/services/data-manager.service';

import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {

  public openMenu = false;
  public baseUrl: string;
  environment = environment;

  constructor(
    public authService: AuthService,
    public dataManager: DataManagerService
  ) { }

  ngOnInit(): void {
  }

  showMenu() {
    this.openMenu = !this.openMenu;
  }

  hideMenu() {
    this.openMenu = false;
  }

  goToSide() {
    this.baseUrl = window.location.host;
    this.dataManager.getNewToken().subscribe(token => {
      location.replace(`https://${environment.switchUrl}/#/login?token=${token}`);
    });
  }

  goToLink(url: string) {
    window.open(url);
  }
}
