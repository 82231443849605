import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './auth/login/login.component';
import { RegisterComponent } from './auth/register/register.component';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { ChangePasswordComponent } from './auth/change-password/change-password.component';
import { AgreementComponent } from './auth/agreement/agreement.component';
import { TeamNameComponent } from './auth/team-name/team-name.component';
import { AuthComponent } from './auth/auth.component';
import { MainComponent } from './main/main.component';
import { BettingComponent } from './main/betting/betting.component';
import { LeaderboardComponent } from './main/leaderboard/leaderboard.component';
import { LeaguesComponent } from './main/leagues/leagues.component';
import { PrivacyComponent } from './main/regulations/privacy/privacy.component';
import { TermsComponent } from './main/regulations/terms/terms.component';
import { RulesComponent } from './main/regulations/rules/rules.component';
import { AccountDetailsComponent } from './main/settings/account-details/account-details.component';
import { LeagueJoinComponent } from './main/leagues/league-join/league-join.component';
import { LeaguesGenerateComponent } from './main/leagues/leagues-generate/leagues-generate.component';
import { LeagueShareComponent } from './main/leagues/league-share/league-share.component';
import { WinnersComponent } from './main/winners/winners.component';
import { AuthGuardService } from './auth/auth-guard.service';
import { ResultsComponent } from './main/betting/results/results.component';
import { LeagueComponent } from './main/leagues/league/league.component';
import { ContactUsComponent } from './main/regulations/contact-us/contact-us.component';
import { PerfectPickComponent } from './main/perfect-pick/perfect-pick.component';


const routes: Routes = [
  {
    path: '',
    component: AuthComponent,
    children: [
      { path: '', component: LoginComponent },
      { path: 'login', component: LoginComponent },
      { path: 'register', component: RegisterComponent },
      { path: 'forgot-password', component: ForgotPasswordComponent },
      { path: 'forgot-password/:token', component: ChangePasswordComponent },
      { path: 'agreement', component: AgreementComponent },
      { path: 'team-name', component: TeamNameComponent },
    ]
  },
  {
    path: 'main',
    component: MainComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: '',
        component: BettingComponent
      },
      {
        path: 'betting',
        component: BettingComponent
      },
      {
        path: 'leaderboard',
        component: LeaderboardComponent
      },
      {
        path: 'leagues',
        component: LeaguesComponent
      },
      {
        path: 'leagues-join',
        component: LeagueJoinComponent
      },
      {
        path:
          'leagues-generate',
        component: LeaguesGenerateComponent
      },
      {
        path: 'leagues-share/:code',
        component: LeagueShareComponent
      },
      {
        path: 'leagues/:id',
        component: LeagueComponent
      },
      {
        path: 'privacy',
        component: PrivacyComponent
      },
      {
        path: 'terms',
        component: TermsComponent
      },
      {
        path: 'rules',
        component: RulesComponent
      },
      {
        path: 'accountdetails',
        component: AccountDetailsComponent
      },
      {
        path: 'winners',
        component: WinnersComponent
      },
      {
        path: 'result',
        component: ResultsComponent
      },
      {
        path: 'contact',
        component: ContactUsComponent
      },
      {
        path: 'perfect-pick',
        component: PerfectPickComponent
      },
    ]
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/login'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
