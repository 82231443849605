import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AuthService, ACCESSTOKEN_STORAGE_KEY } from '../auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Languages } from 'src/app/shared/constants/languages';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public submitted = false;
  public languages = Languages;
  public token: string;

  public formGroup = this.fb.group({
    username: ['', Validators.required],
    password: ['', Validators.required],
  });

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
    public translateService: TranslateService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.token = params.token;
      if (this.token) {
        window.localStorage.setItem(ACCESSTOKEN_STORAGE_KEY, this.token);
        this.authService.token = this.token;
        this.router.navigate(['main']);
      }
    });
  }

  changeLanguage(language: string) {
    localStorage.setItem('fantasyFootballLanguage', language);
    this.translateService.use(language);
  }

  submit() {
    this.submitted = true;
    if (this.formGroup.valid) {
      this.authService.login(this.formGroup.value).subscribe(
        (data) => {
          if (!data.user.agreement) {
            this.router.navigate(['agreement']);
          } else if (!data.user.teamName) {
            this.router.navigate(['team-name']);
          } else if (!data.user.main) {
            this.router.navigate(['main']);
          }
        });
    }
  }

}
