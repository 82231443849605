import { Component, OnInit, Input, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-ticket-warning',
  templateUrl: './ticket-warning.component.html',
  styleUrls: ['./ticket-warning.component.scss']
})
export class TicketWarningComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<TicketWarningComponent>,
    @Inject(MAT_DIALOG_DATA) public boost: number,
  ) { }

  ngOnInit(): void {
  }

  accept(): void {
    this.dialogRef.close(true);
  }

  cancelClick(): void {
    this.dialogRef.close();
  }

}
