import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainComponent } from './main.component';
import { SharedModule } from '../shared/shared.module';
import { BettingModule } from './betting/betting.module';
import { LeaderboardModule } from './leaderboard/leaderboard.module';
import { LeaguesModule } from './leagues/leagues.module';
import { MainMenuComponent } from './main-menu/main-menu.component';
import { RegulationsModule } from './regulations/regulations.module';
import { SettingsModule } from './settings/settings.module';
import { WinnersModule } from './winners/winners.module';
import { CoreModule } from '@angular/flex-layout';
import { PerfectPickModule } from './perfect-pick/perfect-pick.module';



@NgModule({
  declarations: [
    MainComponent,
    MainMenuComponent,
  ],
  imports: [
    CommonModule,
    CoreModule,
    SharedModule,
    BettingModule,
    LeaderboardModule,
    LeaguesModule,
    RegulationsModule,
    SettingsModule,
    WinnersModule,
    PerfectPickModule,
  ]
})
export class MainModule { }
