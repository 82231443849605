import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { DataManagerService } from 'src/app/core/services/data-manager.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  public submitted = false;
  public showSpinner = false;

  public formGroup = this.fb.group({
    email: ['', Validators.required],
  });

  constructor(
    private fb: FormBuilder,
    public dataManager: DataManagerService,
    private router: Router,
    private toastr: ToastrService,
    public translateService: TranslateService,
    private translate: TranslateService,
  ) { }

  ngOnInit(): void {
  }

  submit() {
    this.submitted = true;
    if (!this.formGroup.invalid) {
      this.showSpinner = true;
      this.dataManager.forgotPassword(this.formGroup.value).subscribe(
        (data) => {
          this.showSpinner = false;
          this.translate.get('PLEASE_CHECK_YOUR_EMAIL').subscribe(trans => {
            this.toastr.show(trans);
          });
          this.router.navigate(['login']);
        }, e => {
          this.showSpinner = false;
        }
      );
    }
  }

}
