import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrivacyComponent } from './privacy/privacy.component';
import { TermsComponent } from './terms/terms.component';
import { RulesComponent } from './rules/rules.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { CoreModule } from 'src/app/core/core.module';
import { ContactUsComponent } from './contact-us/contact-us.component';


@NgModule({
  declarations: [
    PrivacyComponent,
    TermsComponent,
    RulesComponent,
    ContactUsComponent,
  ],
  imports: [
    CommonModule,
    CoreModule,
    SharedModule,
  ],
  exports: [
    PrivacyComponent,
    TermsComponent,
    RulesComponent,
    ContactUsComponent,
  ],
  entryComponents: [
  ],
})
export class RegulationsModule { }
