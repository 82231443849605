import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-league-share',
  templateUrl: './league-share.component.html',
  styleUrls: ['./league-share.component.scss']
})
export class LeagueShareComponent implements OnInit {

  public code: string;

  constructor(
    private route: ActivatedRoute,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    this.code = this.route.snapshot.paramMap.get('code');
  }

  copyToClip() {
    const selBox = document.createElement('textarea');

    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.code;

    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();

    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.toastr.success('Copied to clipboard');
  }

  shareCode() {
    let newVariable: any;

    newVariable = window.navigator;

    if (newVariable && newVariable.share) {
      newVariable.share({
        title: 'Fantasy-Football League',
        text: `Join a League with code: ${this.code}`,
        url: window.location.href,
      })
        .then(() => console.log('Successful share'))
        .catch((error) => console.log('Error sharing', error));
    } else {
      this.copyToClip();
    }
  }

}
