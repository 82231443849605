<div class="content">
    <div class="primary-bg"
         style="padding: 30px 8px; text-align: center;">

        <div style="padding: 0px 24px;">
            <div style="margin-top: 35%;">
                <p>{{'CHOOSE_A_NAME' | translate }}</p>
            </div>
            <div style="margin-top: 10%;">
                <p>{{'YOU_CAN_ONLY' | translate }}</p>
            </div>
        </div>

        <div fxLayout="column"
             class="container"
             fxLayoutGap="24px"
             style="margin-top: 10%; margin-bottom: 5%;">

            <app-input-container>
                <input fxFlex
                       type="text"
                       placeholder="Nafn á deild"
                       required
                       [(ngModel)]="leagueName">
            </app-input-container>

            <div>
                <textarea placeholder="{{'DESCRIPTION' | translate }}"
                          [(ngModel)]="description"
                          rows="8"
                          cols="49"
                          maxlength="255"
                          class="font">
                        </textarea>
            </div>

            <button mat-raised-button
                    color="warn"
                    (click)="createLeague()">
                {{'ESTABLISH_A_DEPARTMENT' | translate }}
            </button>

        </div>
    </div>