<div class="content">
    <div class="primary-bg"
         style="padding: 8px;">
        <div fxLayout="row"
             fxLayoutAlign="space-between center">
            <button mat-icon-button
                    (click)="gamesPrevious()"
                    [disabled]="ticket.gameWeekPrevious == 0">
                <mat-icon>arrow_left</mat-icon>
            </button>
            <span>
                {{ticket?.gameWeekName}} : {{ticket?.gameWeekDate}}
            </span>
            <button mat-icon-button
                    (click)="gamesNext()"
                    [disabled]="ticket.gameWeekNext == 0">
                <mat-icon>arrow_right</mat-icon>
            </button>
        </div>

        <div fxLayout="row"
             style="padding: 8px;">
            <button fxFlex
                    mat-stroked-button
                    (click)="randomize()">
                {{'RANDOMIZE' | translate }}
            </button>
        </div>

        <div *ngFor="let prediction of ticket.predictions">
            <app-bet [showResults]="false"
                     [prediction]="prediction"
                     [boost2]="ticket.boost2"
                     (crownChange)="resetCrowns()"
                     (betChange)="calculateQuota()">
            </app-bet>

            <div fxLayout="column"
                 fxLayoutAlign="center center"
                 style="padding: 4px; margin: 8px 0;">
                <span style="height: 2px; width: 80%; background-color: white;"></span>
            </div>

        </div>
        <div style="text-align: center;">
            <h5>{{'NOTE' | translate }}</h5>
        </div>
        <div fxLayout="row">
            <button [ngClass]="{'star-button-selected ': ticket.boost1}"
                    class="star-button"
                    fxFlex
                    mat-stroked-button
                    [disabled]="ticket.boost1Used"
                    (click)="boost1Click()">
                {{'ALL_STAR' | translate }}
            </button>
            <button [ngClass]="{'goals-button-selected ': ticket.boost2}"
                    class="goals-button"
                    fxFlex
                    mat-stroked-button
                    [disabled]="ticket.boost2Used"
                    (click)="boost2Click()">
                {{'TRIPLE_GOALS' | translate }}
            </button>
        </div>
        <div fxLayout="row"
             fxLayoutAlign="space-around center">
            <h5>{{'ALL_GAMES_ARE_DOUBLE' | translate }}</h5>
            <h5>{{'THREE_POINTS_FOR_GOAL_DIFFERENCE' | translate }}</h5>
        </div>

        <div style="margin-top: 10px;">
            <button fxFlex
                    mat-stroked-button
                    (click)="submit()">
                {{'SUBMIT' | translate }}
            </button>
        </div>
    </div>

    <div *ngIf="qouta"
         style="padding: 8px;"
         fxLayout="row"
         fxLayoutAlign="center center">
        <div class="container-img"
             fxLayout="row"
             fxLayoutAlign="center center">
            <div style="background-color: white; height: 180px; width: 95%; padding: 8px;"
                 fxLayout="column"
                 fxLayoutGap="8px">
                <div fxLayout="column"
                     fxLayoutAlign="center center">
                    <div>
                        <img src="../../../../assets/203lengjan.png"
                             style="height: 40px; margin-top: 10px; margin-bottom: 10px;">
                    </div>
                    <div fxLayout="row"
                         fxLayoutAlign="center center"
                         style="margin-bottom: 10px; font-size: 1rem;">
                        <input placeholder="qouta"
                               [(ngModel)]="qoutaMultiplier"
                               (ngModelChange)="calculateQuota()"
                               type="number"
                               style="min-width: 60px; margin-left: 40px;">
                        <div style="color: #253a67; margin: 0 4px;">
                            kr. getur skilað
                        </div>
                        <b style="color: #253a67; min-width: 100px;">{{total}} kr.</b>
                    </div>
                    <div style="color: gray;">
                        STUÐULL: {{qouta}}
                    </div>
                </div>
                <div fxLayout="row">
                    <button mat-raised-button
                            fxFlex
                            style="background-color: #419612; color: white;"
                            (click)="Tippa()">
                        Tippa
                    </button>
                </div>
            </div>
        </div>
    </div>

    <section style="padding: 8px;">
        <div class="mat-elevation-z5"
             style="background-color: white; margin-bottom: 10px;">
            <div class="primary-bg"
                 style="height: 60px; margin-top: 16px;"
                 fxLayoutAlign="center center">
                <span>{{sponsors?.title}}</span>
            </div>
            <div *ngIf="sponsors">
                <img [src]="sponsors.imageSrc"
                     style="width: 100%;">
            </div>
            <div fxLayout="row"
                 fxLayoutAlign="center end"
                 style="padding: 16px;">
                <button mat-raised-button
                        fxFlex
                        color="warn"
                        routerLink="/main/winners">
                    VINNINGAR
                </button>
            </div>
        </div>
    </section>

    <section style="padding: 8px;">
        <div class="primary-bg"
             style="height: 60px; margin-top: 16px;"
             fxLayoutAlign="center center">
            <span>{{'STANDINGS' | translate }}</span>
        </div>
        <div class="mat-elevation-z5"
             style="background-color: white; margin-bottom: 10px; color: black;">

            <mat-table [dataSource]="standings"
                       multiTemplateDataRows
                       style="border-top: 2px solid white;">
                <ng-container matColumnDef="rank">
                    <mat-header-cell *matHeaderCellDef
                                     class="primary-bg"
                                     style="color: white;"
                                     fxFlex="60px">
                        <b>#</b>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let standing"
                              class="primary"
                              fxFlex="60px">
                        {{standing.rank}}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef
                                     class="primary-bg"
                                     style="color: white;"
                                     fxFlex>
                        <b>{{'TEAM' | translate }}</b>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let standing"
                              class="primary"
                              fxFlex>
                        <b>{{standing.name}}</b>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="played">
                    <mat-header-cell *matHeaderCellDef
                                     class="primary-bg"
                                     style="color: white;"
                                     fxFlex="30px">
                        <b>{{'P' | translate }}</b>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let standing"
                              class="primary"
                              fxFlex="30px">
                        {{standing.played}}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="goalsFor">
                    <mat-header-cell *matHeaderCellDef
                                     class="primary-bg"
                                     style="color: white;"
                                     fxFlex="40px">
                        <b>{{'PF' | translate }}</b>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let standing"
                              class="primary"
                              fxFlex="40px">
                        {{standing.goalsFor}}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="goalsAgainst">
                    <mat-header-cell *matHeaderCellDef
                                     class="primary-bg"
                                     style="color: white;"
                                     fxFlex="40px">
                        <b>{{'PA' | translate }}</b>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let standing"
                              class="primary"
                              fxFlex="40px">
                        {{standing.goalsAgainst}}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="win">
                    <mat-header-cell *matHeaderCellDef
                                     class="primary-bg"
                                     style="color: white;"
                                     fxFlex="30px">
                        <b>{{'W' | translate }}</b>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let standing"
                              class="primary"
                              fxFlex="30px">
                        {{standing.win}}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="draw">
                    <mat-header-cell *matHeaderCellDef
                                     class="primary-bg"
                                     style="color: white;"
                                     fxFlex="30px">
                        <b>{{'D' | translate }}</b>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let standing"
                              class="primary"
                              fxFlex="30px">
                        {{standing.draw}}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="loss">
                    <mat-header-cell *matHeaderCellDef
                                     class="primary-bg"
                                     style="color: white;"
                                     fxFlex="40px">
                        <b>{{'L' | translate }}</b>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let standing"
                              class="primary"
                              fxFlex="40px">
                        {{standing.loss}}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="points">
                    <mat-header-cell *matHeaderCellDef
                                     class="primary-bg"
                                     style="color: white;"
                                     fxLayoutAlign="end center"
                                     fxFlex="40px">
                        <b>{{'Pt' | translate }}</b>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let standing"
                              fxLayoutAlign="end center"
                              class="primary"
                              fxFlex="40px">
                        {{standing.points}}
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: columns;"></mat-row>
            </mat-table>
        </div>
    </section>
</div>