<div class="background">
    <div mat-dialog-title
         fxLayout="row"
         fxLayoutAlign="space-between center">
        <div></div>
        <div>
            {{team?.name}}
        </div>
        <button mat-icon-button
                (click)="clickCancel()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <div fxLayout="row"
         fxLayoutGap="8px">
        <div>
            <img [src]="team.leftKit"
                 width="70%">
        </div>
        <div fxLayout="column"
             fxLayoutAlign="center start">
            <div fxLayout="row"
                 fxLayoutGap="2px">
                <span>{{'STADIUM' | translate }}:</span>
                <span>{{team?.stadium}}</span>
            </div>
            <div fxLayout="row"
                 fxLayoutGap="2px">
                <span>{{'COACH' | translate }}:</span>
                <span>{{team?.coach}}</span>
            </div>
        </div>
    </div>
</div>
<div class="primary-bg"
     style="color: white;">
    <div>
        <button mat-raised-button
                [color]="tab === 0 ? 'primary': 'warn'"
                (click)="changeTab(0)"
                fxFlex>
            {{'TEAM_STATISTICS' | translate }}
        </button>
        <button mat-raised-button
                [color]="tab === 1 ? 'primary': 'warn'"
                (click)="changeTab(1)"
                fxFlex>
            {{'GAME_STATISTICS' | translate }}
        </button>
    </div>
    <div fxLayout="row"
         fxLayoutAlign="space-between center">
        <button mat-icon-button
                (click)="changeYear(tableIndex-1)"
                [disabled]="leftButtonDisabled">
            <mat-icon>arrow_left</mat-icon>
        </button>
        <div>
            <span>Tímabil</span>
            <span style="margin-left: 4px;">{{tableName}}</span>
        </div>
        <button mat-icon-button
                (click)="changeYear(tableIndex+1)"
                [disabled]="rightButtonDisabled">
            <mat-icon>arrow_right</mat-icon>
        </button>
    </div>
    <div>
        <mat-table [dataSource]="dataSource"
                   style="width: 100%; max-height: 300px; overflow: auto;"
                   class="primary-bg">

            <ng-container matColumnDef="games">
                <mat-header-cell *matHeaderCellDef
                                 style="color: white; font-size: 1rem;"
                                 fxLayoutAlign="start center"
                                 fxFlex>
                    {{'GAMES' | translate }}
                </mat-header-cell>
                <mat-cell *matCellDef="let row"
                          class="primary"
                          style="background-color: white;"
                          fxLayoutAlign="start center"
                          fxFlex>
                    {{row.games}}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="win">
                <mat-header-cell *matHeaderCellDef
                                 style="color: white; font-size: 1rem;"
                                 fxLayoutAlign="center center"
                                 fxFlex>
                    {{'WIN' | translate }}
                </mat-header-cell>
                <mat-cell *matCellDef="let row"
                          class="primary"
                          style="background-color: white;"
                          fxLayoutAlign="center center"
                          fxFlex>
                    {{row.win}}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="lost">
                <mat-header-cell *matHeaderCellDef
                                 style="color: white; font-size: 1rem;"
                                 fxLayoutAlign="center center"
                                 fxFlex>
                    {{'LOST' | translate }}
                </mat-header-cell>
                <mat-cell *matCellDef="let row"
                          class="primary"
                          style="background-color: white; font-size: 1rem;"
                          fxLayoutAlign="center center"
                          fxFlex>
                    {{row.lost}}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="draw">
                <mat-header-cell *matHeaderCellDef
                                 style="color: white; font-size: 1rem;"
                                 fxLayoutAlign="center center"
                                 fxFlex>
                    {{'DRAW' | translate }}
                </mat-header-cell>
                <mat-cell *matCellDef="let row"
                          class="primary"
                          style="background-color: white;"
                          fxLayoutAlign="center center"
                          fxFlex>
                    {{row.draw}}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="gameweek">
                <mat-header-cell *matHeaderCellDef
                                 style="color: white; font-size: 1rem;"
                                 fxLayoutAlign="start center"
                                 fxFlex>
                    {{'ROUND' | translate }}
                </mat-header-cell>
                <mat-cell *matCellDef="let row"
                          class="primary"
                          style="background-color: white;"
                          fxLayoutAlign="start center"
                          fxFlex>
                    {{row.gameweek}}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="opponent">
                <mat-header-cell *matHeaderCellDef
                                 style="color: white; font-size: 1rem;"
                                 fxLayoutAlign="center center"
                                 fxFlex>
                    {{'OPPONENT' | translate }}
                </mat-header-cell>
                <mat-cell *matCellDef="let row"
                          class="primary"
                          style="background-color: white;"
                          fxLayoutAlign="center center"
                          fxFlex>
                    {{row.opponent}}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="homeAway">
                <mat-header-cell *matHeaderCellDef
                                 style="color: white; font-size: 1rem;"
                                 fxLayoutAlign="center center"
                                 fxFlex>
                    H/A
                </mat-header-cell>
                <mat-cell *matCellDef="let row"
                          class="primary"
                          style="background-color: white;"
                          fxLayoutAlign="center center"
                          fxFlex>
                    {{row.homeAway}}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="winDrawLoss">
                <mat-header-cell *matHeaderCellDef
                                 style="color: white; font-size: 1rem;"
                                 fxLayoutAlign="center center"
                                 fxFlex>
                    S/T/J
                </mat-header-cell>
                <mat-cell *matCellDef="let row"
                          class="primary"
                          style="background-color: white;"
                          fxLayoutAlign="center center"
                          fxFlex>
                    {{row.winDrawLoss}}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="result">
                <mat-header-cell *matHeaderCellDef
                                 style="color: white; font-size: 1rem;"
                                 fxLayoutAlign="center center"
                                 fxFlex>
                    {{'RESULT' | translate }}
                </mat-header-cell>
                <mat-cell *matCellDef="let row"
                          class="primary"
                          style="background-color: white;"
                          fxLayoutAlign="center center"
                          fxFlex>
                    {{row.result}}
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
    </div>
    <div fxLayout="row"
         fxLayoutGap="32px"
         fxLayoutAlign="start center"
         style="height: 40px; font-size: small; padding: 8px;">
        <span>FORM:</span>
        <span>{{tableForm}}</span>
    </div>
</div>