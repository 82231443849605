<div class="primary-bg"
     style="padding: 24px 16px;">

    <h2>PERSÓNUVERNDARSTEFNA</h2>
    <p>Persónuverndarstefna 0101, kt. 540918-0660 er unnin samkvæmt lögum nr. 90/2018 um persónuvernd og meðferð
        persónuupplýsinga.</p>
    <p>0101 seldur aldrei persónuupplýsingar viðskiptavina eða notanda sinna.</p>
    <h3>HVERNIG SAFNAR 0101 UPPLÝSINGUM UM ÞIG?</h3>
    <p>Þegar þú skráir þig sem notanda í leiknum þarft þú að gefa upp tilteknar upplýsingar um þig og einnig skráir</p>
    <ul>
        <li>Upplýsingar sem þú gefur upp eru nafn, netfang, aldur og búsetuland.</li>
        <li>Dæmi um upplýsingar sem verða til við skráningu á síðunni eru upplýsingar á borð við landfræðilegar
            upplýsingar (hvar þú ert stödd/staddur), tungumálastillingar, vafrastillingar og IP tala.</li>
    </ul>
    <h3>HVAÐ tól eru nýtt TIL AÐ SAFNA UPPLÝSINGUM?</h3>
    <p>0101 notar <strong>Google Analytics</strong> til vefmælinga. Þar er skráð tími heimsókna á vefinn, dagsetningar,
        gerð tækis, vafra og stýrikerfis o.fl.</p>
    <p>Í sumum tölvupóstsendingum sem þú munt fá er póstforritið <strong>Mailchimp</strong> notað til að senda notandum
        upplýsingar tengdum leiknum eða samstarfsaðilum.</p>
    <h3>Þínar upplýsingar</h3>
    <p>Þú átt rétt á að fá aðgang að þeim persónuupplýsingum sem við kunnum að hafa um þig. Þú getur líka í sumum
        tilvikum átt rétt á að upplýsingar verði leiðréttar eða þeim eytt.</p>
    <p>Ef þú vilt fá upplýsingar um persónugreinanlegar upplýsingar sem við kunnum að hafa um þig getur sent okkur
        tölvupóst á EMAIL</p>

</div>