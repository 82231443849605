<div class="padding">
    <div class="primary-bg"
         style="padding: 32px 8px;">
        <div>
            {{'PERSONAL_SETTINGS' | translate }}
        </div>
        <form [formGroup]="formGroup">
            <app-input-container>
                <input fxFlex
                       type="text"
                       placeholder="{{'NAME' | translate }}"
                       formControlName="name"
                       autocomplete="name"
                       required>
                <mat-error *ngIf="submitted && formGroup.get('name').hasError('required')">
                    {{'NAME_IS_REQUIRED' | translate }}
                </mat-error>
            </app-input-container>
            <app-input-container>
                <input fxFlex
                       type="text"
                       placeholder="{{'EMAIL' | translate }}"
                       formControlName="email"
                       autocomplete="email"
                       required>
                <mat-error *ngIf="submitted && formGroup.get('email').hasError('required')">
                    {{'EMAIL_IS_REQUIRED' | translate }}
                </mat-error>
            </app-input-container>

            <app-input-container class="pointer"
                                 fxLayout="row"
                                 fxLayoutAlign="space-between center">
                <mat-select formControlName="idCountry"
                            placeholder="{{'COUNTRY' | translate }}"
                            required>
                    <mat-option *ngFor="let country of dataManager.countries"
                                [value]="country.id">
                        {{country.country_name}}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="submitted && formGroup.get('idCountry').hasError('required')">
                    {{'COUNTRY_IS_REQUIRED' | translate }}
                </mat-error>
            </app-input-container>

            <app-input-container>
                <input fxFlex
                       type="text"
                       placeholder="{{'TEAM_NAME' | translate }}"
                       formControlName="teamName"
                       autocomplete="teamName"
                       required>
                <mat-error *ngIf="submitted && formGroup.get('teamName').hasError('required')">
                    {{'TEAM_NAME_IS_REQUIRED' | translate }}
                </mat-error>
            </app-input-container>

            <div fxLayout="row"
                 fxLayoutGap="4px">
                <app-input-container fxFlex>
                    <input fxFlex
                           placeholder="{{'DATA_OF_BIRTH' | translate }}"
                           formControlName="dob"
                           autocomplete="dob"
                           [matDatepicker]="picker"
                           [max]="maxDate">
                    <mat-datepicker #picker></mat-datepicker>
                    <mat-error *ngIf="submitted && formGroup.get('dob').hasError('required')">
                        {{'DATA_OF_BIRTH_REQUIRED' | translate }}
                    </mat-error>
                </app-input-container>
                <mat-datepicker-toggle matSuffix
                                       [for]="picker"
                                       style="
                                  color: white;
                                  position: relative;
                                  top: -4px;">
                </mat-datepicker-toggle>
            </div>

            <div fxLayout="row"
                 fxLayoutAlign="space-between center"
                 style="margin-top: 2%; margin-bottom: 2%; padding: 8px;">
                <span>{{'RECEIVE_NOTIFICATION_EMAILS' | translate }}</span>
                <div>
                    <mat-checkbox style="height: 48px;"
                                  color="warn"
                                  formControlName="emailNotifications">
                    </mat-checkbox>
                </div>
            </div>

            <!-- <div fxLayout="row"
                 fxLayoutAlign="space-between center"
                 style="margin-top: 2%; margin-bottom: 2%;">
                <span style="padding-bottom: 8px;">{{'GENDER' | translate }}</span>
                <div fxLayout="row"
                     fxLayoutGap="8px"
                     fxLayoutAlign="space-between center">
                    <div fxLayout="column"
                         fxLayoutAlign="center center"
                         fxLayoutGap="4px"
                         style="min-width: 60px;">
                        <mat-radio-button color="warn"
                                          style="padding-left: 8px;">
                        </mat-radio-button>
                        <div>{{'MALE' | translate }}</div>
                    </div>
                    <div fxLayout="column"
                         fxLayoutAlign="center center"
                         fxLayoutGap="4px"
                         style="min-width: 60px;">
                        <mat-radio-button color="warn"
                                          style="padding-left: 8px;">
                        </mat-radio-button>
                        <span>{{'FEMALE' | translate }}</span>
                    </div>
                    <div fxLayout="column"
                         fxLayoutAlign="center center"
                         fxLayoutGap="4px"
                         style="min-width: 60px;">
                        <mat-radio-button color="warn"
                                          style="padding-left: 8px;">
                        </mat-radio-button>
                        <span>{{'UNSPECIFIED' | translate }}</span>
                    </div>
                </div>
            </div>-->

            <button class="Change-button"
                    mat-raised-button
                    (click)="changePassword()"
                    color="warn">
                {{'CHANGE_PASSWORD' | translate }}
            </button>

        </form>
        <div style="margin-top: 22%;">
            <button mat-raised-button
                    color='accent'
                    style="color: white;"
                    fxFlex
                    (click)="submit()">
                {{'SAVE_UPDATES' | translate }}
            </button>
        </div>
    </div>
</div>