<app-score *ngIf="userId"></app-score>
<div class="content">
    <div class="primary-bg"
         style="padding: 8px;">
        <div *ngIf="userId"
             fxLayout="row"
             fxLayoutAlign="space-around center">
            <button mat-icon-button
                    fxFlex
                    (click)="backClick()">
                <mat-icon>arrow_back</mat-icon>
            </button>
            <span style="text-align: center;"
                  fxFlex>{{teamName}}</span>
            <div fxFlex></div>
        </div>

        <!--<span fxLayoutAlign="center center"
                  style="padding-top: 15px;">{{'SCORE' | translate }}</span>
            <span fxLayout="row"
                  fxLayoutAlign="center center">
                {{result.gameWeekName}}
            </span>-->

        <div fxLayout="row"
             style="margin-top: 10%;">
            <mat-form-field class="fantasy-select">
                <mat-select [value]="gameweeks[gameweeks.length - 1]?.id">
                    <mat-option *ngFor="let gameweek of gameweeks"
                                [value]="gameweek.id"
                                (click)="getResults(userId,gameweek.id)">
                        {{gameweek.gameweekTitle}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <ng-container *ngIf="result.predictions?.length; else elseTemplate">
            <div fxLayout="column">
                <!-- <div fxLayout="row"
                 fxLayoutGap="2px">
                <button mat-flat-button
                        mat-icon-button
                        fxFlex>
                    <div fxLayout="row"
                         fxLayoutAlign="center center">
                        <mat-icon>navigate_before</mat-icon>
                        <span>{{'PREVIOUS' | translate }}</span>
                    </div>
                </button>
                <button mat-flat-button
                        mat-icon-button
                        fxFlex>
                    <div fxLayout="row"
                         fxLayoutAlign="center center">
                        <span>{{'NEXT' | translate }}</span>
                        <mat-icon>navigate_next</mat-icon>
                    </div>
                </button>
            </div> -->


                <div fxLayout="row"
                     class="warn-bg"
                     style="height: 120px; padding: 8px; margin-bottom: 4px;">
                    <div fxLayout="column"
                         fxLayoutAlign="center center"
                         fxFlex>
                        <span style="font-size: 1.2rem;"><b>{{result.yourScore}}</b></span>
                        <span>{{'YOUR_SCORE' | translate }}</span>
                    </div>
                    <div fxLayout="column"
                         fxLayoutAlign="center center"
                         fxFlex>
                        <div fxLayout="column"
                             fxLayoutGap="8px">
                            <div fxLayout="row"
                                 fxLayoutGap="2px">
                                <span>{{'AVERAGE' | translate }}:</span>
                                <span><b>{{result.average}}</b></span>
                            </div>
                            <div fxLayout="row"
                                 fxLayoutGap="2px">
                                <span>{{'HIGHEST' | translate }}:</span>
                                <span><b>{{result.highest}}</b></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngFor="let prediction of result.predictions">
                <app-bet [showResults]="true"
                         [prediction]="prediction"
                         [boost2]="result?.boost2">
                </app-bet>

                <div fxLayout="column"
                     fxLayoutAlign="center center"
                     style="padding: 4px; margin: 12px 0;">
                    <span style="height: 2px; width: 80%; background-color: white;"></span>
                </div>
            </div>
        </ng-container>
        <ng-template #elseTemplate>
            <div style="padding: 60px; height: 200px;"
                 fxLayout="column"
                 fxLayoutAlign="center center">
                <span style="text-align: center;">
                    {{'NO_RESULTS_TEXT' | translate }}</span>
            </div>
        </ng-template>
    </div>

    <section style="padding: 0 8px;"
             *ngIf="!userId">
        <div fxLayout="column"
             class="mat-elevation-z8"
             style="background-color: white; margin-top: 20px;">
            <div class="primary-bg"
                 style="height: 60px;"
                 fxLayoutAlign="center center">
                <span>{{'RESULTS' | translate }}</span>
            </div>
            <div class="mat-elevation-z5">
                <mat-table [dataSource]="matches">

                    <ng-container matColumnDef="startDate">
                        <mat-cell *matCellDef="let match"
                                  fxFlex>
                            <div class="primary"
                                 fxLayout="column"
                                 fxLayout.gt-md="row">
                                <span>{{match.startDate}}</span>
                                <span style="margin-left: 5px;">{{match.startTime}}</span>
                            </div>
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="nicknnameHomeTeam">
                        <mat-cell *matCellDef="let match"
                                  class="primary"
                                  fxFlex="40px">
                            <b>{{match.homeTeam.nickname}} </b>
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="LogoSrcHomeTeam">
                        <mat-cell *matCellDef="let match"
                                  fxFlex="30px">
                            <img [src]="match.homeTeam.logoSrc"
                                 style="max-width: 30px;">
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="points">
                        <mat-cell *matCellDef="let match"
                                  class="primary"
                                  fxLayoutAlign="center center"
                                  fxFlex="50px">
                            {{match.homePoints}}:{{match.awayPoints}}
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="logoSrcAwayTeam">
                        <mat-cell *matCellDef="let match"
                                  fxFlex="30px">
                            <img [src]="match.awayTeam.logoSrc"
                                 style="max-width: 30px;">
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="nicknameAwayTeamnickname">
                        <mat-cell *matCellDef="let match"
                                  class="primary"
                                  fxLayoutAlign="end center"
                                  fxFlex="50px">
                            <b>{{match.awayTeam.nickname}}</b>
                        </mat-cell>
                    </ng-container>

                    <mat-row
                             *matRowDef="let row; columns: ['startDate', 'nicknnameHomeTeam', 'LogoSrcHomeTeam', 'points', 'logoSrcAwayTeam', 'nicknameAwayTeamnickname'];">
                    </mat-row>
                </mat-table>
            </div>
        </div>

        <div fxLayout="column"
             class="mat-elevation-z5"
             style="background-color: white; margin-top: 20px;">
            <div class="primary-bg"
                 style="height: 60px;"
                 fxLayoutAlign="center center">
                <span>{{'LEADERBOARD' | translate }}</span>
            </div>
            <app-leaderboard-list [leaderboardItems]="leaderboardItems">
            </app-leaderboard-list>
            <div fxLayout="row"
                 fxLayoutAlign="center end"
                 style="padding: 16px;">
                <button mat-raised-button
                        fxFlex
                        color="warn"
                        routerLink="/main/leaderboard">
                    {{'LEADERBOARD' | translate }}
                </button>
            </div>
        </div>

        <div fxLayout="column"
             class="mat-elevation-z5"
             style="background-color: white; margin-top: 20px; margin-bottom: 10px;">
            <div class="primary-bg"
                 style="height: 60px;"
                 fxLayoutAlign="center center">
                <span>{{'YOUR_LEAGUES' | translate }}</span>
            </div>
            <app-leagues-list></app-leagues-list>
            <div fxLayout="row"
                 fxLayoutAlign="center end"
                 style="padding: 16px;">
                <button mat-raised-button
                        fxFlex
                        color="warn"
                        routerLink="/main/leagues">
                    {{'SEE_ALL_LEAGUES' | translate }}
                </button>
            </div>
        </div>
    </section>
</div>