import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DataManagerService } from 'src/app/core/services/data-manager.service';
import { Sponsors } from 'src/app/shared/models/sponsor';
import { Gameweek } from 'src/app/shared/models/gameweek';

@Component({
  selector: 'app-winners',
  templateUrl: './winners.component.html',
  styleUrls: ['./winners.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class WinnersComponent implements OnInit {

  public sponsors: Sponsors = {} as Sponsors;
  public gameweeks: Gameweek[] = [];

  constructor(
    public dataManager: DataManagerService,
    ) {
    this.getGameweeks();
  }

  ngOnInit(): void {
  }

  getSponsors(gameweekId?) {
    this.dataManager.getSponsors(gameweekId).subscribe(sponsors => this.sponsors = sponsors);
  }

  getGameweeks() {
    this.dataManager.getGameweeks(true).subscribe(gameweeks => {
      this.gameweeks = gameweeks;
      this.getSponsors(gameweeks[gameweeks.length - 1]?.id);
    });
  }
}
