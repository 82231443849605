import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-betting',
  templateUrl: './betting.component.html',
  styleUrls: ['./betting.component.scss']
})
export class BettingComponent implements OnInit {
  public tab = 0;

  constructor() { }

  ngOnInit(): void {
  }

  changeTab(tab) {
    this.tab = tab;
  }

}
