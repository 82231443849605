<div class="content mat-elevation-z5">
    <div fxLayout="row"
         fxLayoutAlign="space-between end"
         style="padding: 16px;">

        <img class="pointer noSelect"
             src="../../assets/logo.svg"
             style="height: 50px;"
             routerLink="/main"
             (click)="hideMenu()">
        <div fxLayout="column"
             fxLayoutAlign="center center">
            <img class="pointer noSelect"
                 src="../../assets/arrows2.png"
                 style="height: 30px;"
                 routerLink="/main"
                 (click)="goToSide()">
            <span>{{environment.switchTitle | translate | uppercase}}</span>
        </div>

        <button mat-icon-button
                (click)="showMenu()">
            <mat-icon>{{openMenu ? 'close' : 'menu' }}</mat-icon>
        </button>
    </div>

    <app-main-menu *ngIf="openMenu"
                   (hideMenu)="hideMenu()">
    </app-main-menu>

    <div [ngStyle]="{'display': openMenu ? 'none' : 'block'}">
        <router-outlet>
        </router-outlet>
    </div>

    <div fxLayout="column"
         style="background-color: #253a67; padding: 12px;">
        <span style="height: 2px; background-color: white; margin-bottom: 2%;"></span>
        <div fxLayoutAlign="center center">
            <span>{{'FOOTER_TITLE' | translate }}</span>
        </div>
        <div fxLayoutAlign="center center">
            <a style="color: white;"
               href="https://www.0101.is"
               target="_blank">0101.is</a>
        </div>
        <div fxLayout="row"
             fxLayoutAlign="center center"
             fxLayoutGap="16px">
            <button mat-icon-button
                    (click)="goToLink('https://www.facebook.com/Pikkið-104118777985807')">
                <img src="../../assets/social icons/facebook.svg"
                     height="24px">
            </button>
        </div>
        <div fxLayout="row"
             fxLayoutAlign="center center"
             fxLayoutGap="32px">
            <a style="color: white; text-align: right;"
               routerLink="/main/terms"
               fxFlex>{{'TERMS_&_CONDITIONS' | translate }}</a>
            <a style="color: white; text-align: left;"
               routerLink="/main/privacy"
               fxFlex>{{'PRIVACY_POLICY' | translate }}</a>
        </div>
        <span style="height: 2px; background-color: white; margin-top: 8px;"></span>
        <div fxLayout="row"
             fxLayoutAlign="center center"
             style="margin-top: 4px;">
            <span>Copyright 0101</span>
        </div>
    </div>
</div>