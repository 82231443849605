import { Component, OnInit } from '@angular/core';
import { DataManagerService } from 'src/app/core/services/data-manager.service';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { Result } from 'src/app/shared/models/result';
import { Match } from 'src/app/shared/models/match';
import { map, tap } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { LeaderboardItem } from 'src/app/shared/models/leaderboardItem';
import { Gameweek } from 'src/app/shared/models/gameweek';

@Component({
  selector: 'app-results',
  templateUrl: './results.component.html',
  styleUrls: ['./results.component.scss']
})
export class ResultsComponent implements OnInit {

  public result: Result = {} as Result;
  public matches: Match[];
  public userId: string;
  public teamName: string;
  public leaderboardItems: LeaderboardItem[];
  public gameweeks: Gameweek[] = [];

  constructor(
    public dataManager: DataManagerService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private location: Location,
    private toastr: ToastrService,
  ) {
    this.getMatches();
    this.getLeaderboardOverall();
    this.getGameweeks();
  }

  ngOnInit(): void {
    this.route
      .queryParamMap
      .pipe(
        tap(params => {
          this.userId = params.get('userId') || undefined;
          this.teamName = params.get('teamName');
        })
      )
      .subscribe(() => this.getResults(this.userId));
  }

  getResults(userId, gameweekId?) {
    this.dataManager.getResults(userId, gameweekId).subscribe(result => {
      this.result = result;
      if (this.result.boost1) {
      this.result.predictions.forEach(prediction => {
        prediction.matchStar = true;
      });
    }
    });
  }

  getMatches() {
    this.dataManager.getMatches().subscribe(res => {
      this.matches = res.matches;
    });
  }

  getLeaderboardOverall() {
    this.dataManager.getLeaderboardOverall().subscribe(leaderboardItems => {
      this.leaderboardItems = leaderboardItems;
    });
  }

  getGameweeks() {
    this.dataManager.getGameweeks().subscribe(gameweeks => {
      this.gameweeks = gameweeks;
    });
  }

  backClick() {
    this.location.back();
  }

}
