import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LinkHtmlService } from './shared/services/link-html.service';
import { environment } from 'src/environments/environment';
import { Meta } from '@angular/platform-browser';
import { Router, NavigationEnd } from '@angular/router';

// tslint:disable-next-line:ban-types
declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'fantasy-football';

  environment = environment;

  constructor(
    private translate: TranslateService,
    private link: LinkHtmlService,
    private metaService: Meta,
    public router: Router,
  ) {
    this.router.events.subscribe(event => {

      if (event instanceof NavigationEnd) {
        gtag('config', 'UA-148741075-5', { page_path: event.urlAfterRedirects });
      }

    });
    this.translate.addLangs(['fantasy-football-en-US', 'fantasy-football-is-IS']);
    this.translate.setDefaultLang('fantasy-football-is-IS');
    this.translate.use(localStorage.getItem('fantasyFootballLanguage') || 'fantasy-football-is-IS');
    this.metaService.addTags([
      {
        name: 'keywords',
        content: `${environment.metaKeywords}`,
      },
      {
        name: 'description',
        content: `${environment.metaDescription}`,
      },
      {
        property: 'og:title',
        content: `${environment.metaOgTitle}`,
      },
      {
        property: 'og:description',
        content: `${environment.metaDescription}`,
      },
      {
        property: 'og:type',
        content: 'website',
      },
      {
        property: 'og:image:alt',
        content: `${environment.metaOgImageAlt}`,
      },
      {
        property: 'og:image',
        content: `${environment.socialUrl}`,
      },
      {
        property: 'og:image:width',
        content: '1200',
      },
      {
        property: 'og:image:height',
        content: '628',
      },
    ], true);
  }
}
