import { Component, OnInit } from '@angular/core';
import { DataManagerService } from 'src/app/core/services/data-manager.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-league-join',
  templateUrl: './league-join.component.html',
  styleUrls: ['./league-join.component.scss']
})
export class LeagueJoinComponent implements OnInit {

  public code: string;

  constructor(
    public dataManager: DataManagerService,
    private router: Router,
  ) { }

  ngOnInit(): void {
  }

  joinLeague() {
    if (this.code) {
      this.dataManager.joinLeague(this.code).subscribe(res => this.router.navigate(['/main/leagues']));
    }
  }

}
