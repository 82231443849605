import { Component, OnInit } from '@angular/core';
import { DataManagerService } from 'src/app/core/services/data-manager.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-leagues-generate',
  templateUrl: './leagues-generate.component.html',
  styleUrls: ['./leagues-generate.component.scss']
})
export class LeaguesGenerateComponent implements OnInit {

  public leagueName: string;
  public description: string;

  constructor(
    public dataManager: DataManagerService,
    private router: Router,
  ) { }

  ngOnInit(): void {
  }

  createLeague() {
    if (this.leagueName) {
      this.dataManager.postLeagues(this.leagueName, this.description).subscribe(
        res => this.router.navigate(['/main/leagues-share', res.code])
      );
    }
  }

}
