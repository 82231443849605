<div class="primary-bg"
     style="padding: 24px 16px;">
    <h2>Reglur</h2>
    <p>Markmið leiksins er að spá fyrir um úrslit í hverri umferð Pepsi Max deildarinnar í fótbolta. Annarsvegar þarf að
        spá fyrir um úrslit leiksins (1,x eða 2) og hinsvegar markamun, eða úrslit leiks í tilfellum þar sem jafntefli
        er spáð. Stig eru veitt fyrir þá leiki sem er rétt spáð fyrir um og safnast stigin þín saman yfir allt
        tímabilið.</p>
    <p>Allir sem skrá sig til leiks keppa í Topplistanum þar sem vegleg verðlaun eru veitt í lok tímabils og smærri
        vinningar fyrir hverja umferð. Einnig er hægt að stofna einkadeildir þar sem vinir geta keppt innbyrðis í
        leiknum.</p>
    <h3>Stig</h3>
    <p>Fyrir hver rétt spáð úrslit fást <strong>3 stig</strong> og fyrir réttan markamun fæst <strong>1 stig</strong>.
        Athugið að aðeins eru veitt stig fyrir markamun ef spáð úrslit eru rétt.</p>
    <p>Í hverri umferð er valin einn leikur sem gildir tvöfalt, þ.e. öll stig sem fást fyrir þann leik margfaldast með
        2.</p>
    <h3>Margfaldarar (boosters)</h3>
    <p>Hver notandi hefur til umráða tvo margfaldara yfir tímabilið. Ef margfaldari er notaður eykur hann líkur á hærra
        skori þá umferðina. Aðeins er hægt að nota hvern margfaldara einu sinni og aðeins má nota einn margfaldara í
        umferð.</p>
    <ul>
        <li>Tvöfaldari – Allir leikir þeirrar umferðar gilda tvöfalt.<br>Athugið að þegar þessi margfaldari er virkur er
            leikur sem gildir tvöfalt ekki valin eins og venjulega.</li>
        <li>Þrenna – Öll stig fengin fyrir markamun eru þrefölduð.<br>Tvöfaldi leikur umferðar gildir með þessum
            margfaldara.</li>
    </ul>
    <h3>Umferðir</h3>
    <p>Leikir hverrar umferðar fylgja nokkurn vegin dagskrá Pepsi max deildarinnar. Skila þarf inni sinni spá fyrir
        hverja umferð áður en fyrsti leikur þeirrar umferðar hefst. Ef notandi skilar ekki inn spá fyrir tiltekna umferð
        er slembispá gerð af kerfinu, sú spá er aðgengileg um leið og lokað er fyrir umferð. Strax og umferð lokar er
        hægt að spá fyrir næstu umferð.</p>
    <h3>Frestaðir leikir</h3>
    <p>Ef leik er frestað í umferð sem búið er að birta á vefnum telur leikurinn ef hann spilast innan 48 klukkustunda
        síðar. Ef ekki verður frestaður leikur partur af umferð seinna á tímabilinu. Rekstaraðili getur endurskoðað
        þessa reglu við sérstakar eða óeðlilega aðstæður.</p>
    <h3>Vinningar</h3>
    <p>Ýmsir vinningar verða í boði yfir tímabilið og birtast þeir undir síðunni <a style="color: #EC2E55;"
           routerLink="/main/winners"
           target="_self">vinningar</a> ásamt „smá letrinu“. <strong>Athugið að aðeins einn reikningur er leyfður á
            hvern einstakling.</strong></p>
    <h3>Jöfn stig (e. Tiebreakers)</h3>
    <p>Ef fleiri en einn notandi er með sama stigafjölda er skorið úr um sigurvegara á eftirfarandi hátt.</p>
    <p>Ef um einstaka umferð er að ræða:</p>
    <ol>
        <li>Hver var með hæsta einstaka skor fyrir einn ákveðin leik (margfaldarar telja) eða fleiri leiki með hæsta skor ef allir aðilar eru með jafn mörg stig fyrir sitt best pikk.</li>
        <li>Hver var með fleiri rétt pikkuð úrslit (1,x eða 2)</li>
        <li>Hver var með fleiri rétt pikk um markamun, óháð því hvort úrslit voru rétt pikkuð.</li>
    </ol>
    <p>Ef ekki næst að útkljá sigurvegara að þessum skrefum loknum ráðast úrslit leiksins með hlutkesti, framkvæmt af rekstaraðila leiksins.</p>
    <p>Ef um topplistann fyrir allt tímabilið er að ræða:</p>
    <ol>
        <li>Hver var með hæsta einstaka skor fyrir eina ákveðna umferð (margfaldarar telja).</li>
        <li>Hver var með hæsta einstaka skor fyrir einn ákveðin leik (margfaldarar telja) eða fleiri leiki með hæsta skor ef allir aðilar eru með jafn mörg stig fyrir sitt best pikk.</li>
        <li>Hver var með fleiri rétt pikkuð úrslit (1,x eða 2)</li>
        <li>Hver var með fleiri rétt pikk um markamun, óháð því hvort úrslit voru rétt pikkuð.</li>
    </ol>
    <p>Ef ekki næst að útkljá sigurvegara að þessum skrefum loknum ráðast úrslit leiksins með hlutkesti, framkvæmt af rekstaraðila leiksins.</p>
    <h3>Einkadeildir</h3>
    <p>Einkadeildir telja til stiga frá þeim tímapunkti sem notandi skráir sig í deildina. Stig fyrri umferða telja ekki
        með ef skráning fer fram eftir að einhverjar umferðir hafa spilast.</p>
</div>