import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BettingComponent } from './betting.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { BetComponent } from './bet/bet.component';
import { ResultsComponent } from './results/results.component';
import { TicketComponent } from './ticket/ticket.component';
import { LeaderboardModule } from '../leaderboard/leaderboard.module';
import { LeaguesModule } from '../leagues/leagues.module';
import { TeamOverviewComponent } from './team-overview/team-overview.component';
import { CoreModule } from 'src/app/core/core.module';
import { TicketWarningComponent } from './ticket/ticket-warning/ticket-warning.component';



@NgModule({
  declarations: [
    BettingComponent,
    TicketComponent,
    BetComponent,
    ResultsComponent,
    TeamOverviewComponent,
    TicketWarningComponent,

  ],
  imports: [
    CommonModule,
    CoreModule,
    SharedModule,
    LeaderboardModule,
    LeaguesModule,
  ],
  exports: [
  ],
  entryComponents: [
    TeamOverviewComponent,

  ],
})
export class BettingModule { }
