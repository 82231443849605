<app-score></app-score>
<div class="content">
    <div style="padding: 8px;"
         class="mat-elevation-z8">
        <div style="background-color: white; margin-top: 20px;">
            <div class="primary-bg"
                 style="padding: 8px;">
                <div fxLayoutAlign="center end">
                    <h2>{{'YOUR_LEAGUES' | translate }}</h2>
                </div>
            </div>
            <app-leagues-list></app-leagues-list>
        </div>
        <div fxLayout="row"
             fxLayoutAlign="center end"
             style="margin-top: 43%;">
            <button mat-raised-button
                    fxFlex
                    color="warn"
                    routerLink="/main/leagues-join">
                {{'JOIN_LEAGUES' | translate }}
            </button>
        </div>
        <div fxLayout="row"
             fxLayoutAlign="center end"
             style="margin-top: 4%;">
            <button mat-raised-button
                    fxFlex
                    color="primary"
                    routerLink="/main/leagues-generate">
                {{'CREATE_LEAGUES' | translate }}
            </button>
        </div>
    </div>
</div>