<div class="primary-bg">
    <div mat-dialog-title
         style="color: white;"
         fxLayoutAlign="space-between center">
        <div></div>
        <span>TVÖFALDARI</span>
        <button mat-icon-button
                (click)="cancelClick()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <div style="padding: 32px; color: white;">
        <div *ngIf="boost === 1">
            Tvöfaldarinn lætur alla leikina gilda tvöfalt í þessari umferð. Athugaðu að ef þú staðfestir val með
            margfaldara
            á
            getur þú ekki hætt við. Þannig vertu viss!
        </div>
        <div *ngIf="boost === 2">
            Þrennan virkjar margfaldara þannig þú færð 3x stig fyrir öll rétt markaskor. Athugaðu að ef þú staðfestir
            val
            með
            margfaldara á getur þú ekki hætt við. Þannig vertu viss!
        </div>
    </div>
</div>

<div fxLayoutAlign="end center">
    <button fxFlex
            mat-raised-button
            color="accent"
            style="color: white;"
            (click)="accept()">
        {{'ACCEPT' | translate }}
    </button>
    <button fxFlex
            mat-raised-button
            color="warn"
            (click)="cancelClick()">
        {{'CANCEL' | translate }}
    </button>
</div>