import { Component, OnInit, Inject } from '@angular/core';
import { BettingComponent } from '../betting.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataManagerService } from 'src/app/core/services/data-manager.service';
import { Team } from 'src/app/shared/models/team';

@Component({
  selector: 'app-team-overview',
  templateUrl: './team-overview.component.html',
  styleUrls: ['./team-overview.component.scss']
})
export class TeamOverviewComponent implements OnInit {

  public displayedColumns: string[] = [];
  public dataSource: any = [];
  public tab = 0;
  public team: Team = {} as Team;
  public tableIndex = 0;
  public tableName = '';
  public leftButtonDisabled = true;
  public rightButtonDisabled = true;
  public tableForm = '';

  constructor(
    public dataManager: DataManagerService,
    public dialogRef: MatDialogRef<BettingComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.getTeam();
  }

  ngOnInit(): void {
  }

  getTeam() {
    this.dataManager.getTeam(this.data.id).subscribe(team => {
      this.team = team;
      this.changeTab(0);
    });
  }

  clickCancel(): void {
    this.dialogRef.close();
  }

  changeTab(tab) {
    this.tab = tab;
    if (tab === 0) {
      this.displayedColumns = ['games', 'win', 'lost', 'draw'];
      this.tableIndex = 0;
      this.dataSource = this.team.statisticsTeam[0].data;
      this.tableName = this.team.statisticsTeam[0].name;
      this.tableForm = this.team.statisticsTeam[0].formInfo;
      this.changeTableButtonsState(this.tableIndex, this.team.statisticsTeam);
    } else if (tab === 1) {
      this.displayedColumns = ['gameweek', 'opponent', 'homeAway', 'winDrawLoss', 'result'];
      this.tableIndex = 0;
      this.dataSource = this.team.statisticsAllGames[0].data;
      this.tableName = this.team.statisticsAllGames[0].name;
      this.tableForm = this.team.statisticsAllGames[0].formInfo;
      this.changeTableButtonsState(this.tableIndex, this.team.statisticsAllGames);
    }
  }

  changeYear(tableIndex: number) {
    this.tableIndex = tableIndex;
    if (this.tab === 0) {
      if (tableIndex < this.team.statisticsTeam.length) {
        this.dataSource = this.team.statisticsTeam[tableIndex].data;
        this.tableName = this.team.statisticsTeam[tableIndex].name;
        this.tableForm = this.team.statisticsTeam[tableIndex].formInfo;
      }
      this.changeTableButtonsState(tableIndex, this.team.statisticsTeam);
    }

    if (this.tab === 1) {
      if (tableIndex < this.team.statisticsAllGames.length) {
        this.dataSource = this.team.statisticsAllGames[tableIndex].data;
        this.tableName = this.team.statisticsAllGames[tableIndex].name;
        this.tableForm = this.team.statisticsAllGames[tableIndex].formInfo;
      }
      this.changeTableButtonsState(tableIndex, this.team.statisticsAllGames);
    }
  }

  private changeTableButtonsState(tableIndex: number, tableData: any) {
    if (tableIndex === 0) {
      this.leftButtonDisabled = true;
      this.rightButtonDisabled = false;
    } else if (tableIndex > 0 && tableIndex < tableData.length - 1) {
      this.leftButtonDisabled = false;
      this.rightButtonDisabled = false;
    } else {
      this.leftButtonDisabled = false;
      this.rightButtonDisabled = true;
    }
  }

}
