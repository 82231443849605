<div fxLayout="row"
     fxLayoutAlign="center end"
     class="container">

     <div fxLayout="column"
          fxLayoutAlign="center center"
          style="position: fixed; top: 10%;">
          <img src="../../assets/logo.svg"
               style="margin-bottom: 20px; height: 120px;" />
          <p class="logo-text">{{environment.logoText}}</p>
          <p class="logo-text-title">{{environment.logoTextTitle}}</p>
     </div>
     <div fxLayout="column"
          class="content"
          fxLayoutGap="8px">

          <router-outlet>
          </router-outlet>

     </div>
</div>