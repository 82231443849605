<div class="content">
    <div class="mat-elevation-z8"
         style="padding: 32px 16px; text-align: center;">
        <div fxLayout="row"
             fxLayoutAlign="center center">
            <p>{{'PERFECT_PICK_TITLE' | translate }}</p>
        </div>
        <div class="primary-bg">
            <div>
                <img src="../../../assets/lengjan.png"
                     style="width: 100%;">
            </div>
            <div class="primary-bg"
                 fxLayoutAlign="center center">
                <div style="padding: 16px;">
                    <p>Fullkomið Pikk þýðir að allir leikir umferðarinnar eru rétt pikkaðir, bæði úrslit og markamunur.
                    </p>
                    <p>Sá sem nær fullkomnu Pikki í heilli umferð fær vegleg verðlaun.
                        Verðlaunin hækka í hverri umferð ef vinninngurinn gengur ekki út.</p>
                </div>
            </div>
            <img [src]="sponsors.imageSrc"
                 style="width: 100%;">
            <div>
                <p>Þeir Pikkarar sem eru eldri en 18 ára mega taka þátt og eru sjálfkrafa skráðir inn</p>
            </div>
            <div style="margin-top: 20px;">
                <a style="color: #EC2E55;"
                   href="https://games.lotto.is" target="_blank">Lengjan.is</a>
            </div>
        </div>
    </div>
</div>