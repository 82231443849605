import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { DataManagerService } from 'src/app/core/services/data-manager.service';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss']
})
export class MainMenuComponent implements OnInit {

  @Output() hideMenu = new EventEmitter<boolean>();

  constructor(
    public dataManager: DataManagerService,
    public authService: AuthService,
  ) { }

  ngOnInit(): void {
  }

  signOut() {
    this.authService.logout();
  }

  hide() {
    this.hideMenu.emit();
  }

}
