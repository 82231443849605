<div style="background-color: white; margin-bottom: 10px; min-height: 300px;">
    <mat-table [dataSource]="leaderboardItems">
        <ng-container matColumnDef="ord">
            <mat-cell *matCellDef="let row"
                      class="white"
                      fxFlex="60px"
                      [ngClass]="{'primary': !row.highlighted}">
                {{row.ord}}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="teamName">
            <mat-cell *matCellDef="let row"
                      class="accent pointer"
                      fxFlex
                      [ngClass]="{'warn': !row.highlighted}">
                <b>{{row.teamName}}</b>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="name">
            <mat-cell *matCellDef="let row"
                      class="white"
                      style="margin-left: 18px;"
                      fxFlex
                      [ngClass]="{'primary': !row.highlighted}">
                <b>{{row.name}}</b>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="points">
            <mat-cell *matCellDef="let row"
                      class="white"
                      fxLayoutAlign="end center"
                      fxFlex
                      [ngClass]="{'primary': !row.highlighted}">
                <div fxLayout="row"
                     fxLayoutAlign="center center">
                    {{row.points}} {{'PT' | translate }}
                    <mat-icon style="margin-left: 8px;">arrow_right</mat-icon>
                </div>
            </mat-cell>
        </ng-container>

        <mat-row *matRowDef="let row; columns: ['ord', 'teamName', 'name', 'points'];"
                 [ngClass]="{'primary-bg': row.highlighted}"
                 (click)="showResults(row.id, row.teamName)">
        </mat-row>
    </mat-table>
</div>