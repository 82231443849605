export const environment = {
  production: false,
  loginUrl: 'https://api.pikkid.is/oauth/token',
  apiUrl: 'https://api.pikkid.is/api',
  socialUrl: 'https://api.pikkid.is/data/media/social/social_1x2.png',
  switchUrl: 'enski.pikkid.is',
  switchTitle: 'ENGLISH_PREMIER_LEAGUE',
  logoText: 'Pepsi-Max deild',
  logoTextTitle: 'Karla',
  metaDescription: 'Pikkið - 1x2 leikur fyrir Pepsi-Max deild karla í fótbolta. Vinningar í hverri viku.',
  metaKeywords: 'Pikkið, Pepsi-Max deildin, 1x2, fótbolti ísland, Icelandic football',
  metaOgTitle: 'Pikkið - Pepsi-Max deild karla',
  metaOgImageAlt: '1x2.pikkid.is',
};
