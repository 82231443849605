<div fxLayout="column"
     fxLayoutAlign="start center">
    <div (click)="crownClick()"
         style="margin: 4px 0; min-height: 34px;">
        <img *ngIf="prediction.matchStar"
             src="../../../../assets/crown_full.svg"
             target
             [@wobble]="wobbleState"
             (@wobble.done)="reset()">
        <img *ngIf="!prediction.matchStar"
             src="../../../../assets/crown.svg">
    </div>

    <div fxLayout="row">

        <div fxFlex
             class="jersey-home pointer"
             (click)="showTeam(prediction.sportTeamIdHomeTeam)">
            <img [src]="prediction.sportTeamIdHomeTeamKit"
                 width="100%">
        </div>

        <div fxFlex>
            <ng-container *ngIf="showProbability; else elseBetTemplate">
                <div class="warn-bg"
                     fxLayout="column"
                     style="border: 1px solid; width: 240px; height: 108px;">
                    <div fxLayoutAlign="center center"
                         fxFlex
                         style="font-size: 0.7rem;">
                        {{'USER_PICKS' | translate }}
                    </div>
                    <div fxLayout="row"
                         fxLayoutAlign="space-around center"
                         style="height: 12px;">
                        <b>{{prediction.probability.userPicks.number1}}%</b>
                        <b>{{prediction.probability.userPicks.number2}}%</b>
                        <b>{{prediction.probability.userPicks.number3}}%</b>
                    </div>
                    <div fxLayoutAlign="center center"
                         fxFlex
                         style="font-size: 0.7rem;">
                        {{'ODDS' | translate }}
                    </div>
                    <div fxLayout="row"
                         fxLayoutAlign="space-around center">
                        <b>{{prediction.probability.probability.number1}}</b>
                        <b>{{prediction.probability.probability.number2}}</b>
                        <b>{{prediction.probability.probability.number3}}</b>
                    </div>
                    <div style="height: 1px; background-color: white; margin-top: -1px;"></div>
                    <div fxLayout="row">
                        <button class="bet-button"
                                fxFlex
                                mat-button
                                (click)="showProbabilityClick()">
                            {{'PROBABILITY' | translate | uppercase }}
                            <mat-icon>arrow_drop_down</mat-icon>
                        </button>
                    </div>
                </div>

            </ng-container>
            <ng-template #elseBetTemplate>
                <div fxLayout="column"
                     fxLayoutGap="8px"
                     style="width: 240px;">
                    <div fxLayout="row">
                        <button class="bet-button"
                                [ngClass]="{'team-button-selected': prediction.winSelection===1, 'team-win-button-selected': prediction.winSelection===1 && prediction.winSelectionHit===1 && showResults, 'team-lose-button-selected': prediction.winSelection===1 && prediction.winSelectionHit===0 && showResults}"
                                mat-stroked-button
                                fxFlex
                                [disabled]="showResults"
                                (click)="teamClick(1)">
                            {{prediction.sportTeamIdHomeTeamName}}
                        </button>
                        <button class="bet-button"
                                [ngClass]="{'team-button-selected': prediction.winSelection===2,  'team-win-button-selected': prediction.winSelection===2 && prediction.winSelectionHit===1 && showResults,'team-lose-button-selected': prediction.winSelection===2 && prediction.winSelectionHit===0 && showResults}"
                                mat-stroked-button
                                fxFlex
                                [disabled]="showResults"
                                (click)="teamClick(2)">
                            DRAW
                        </button>
                        <button class="bet-button"
                                [ngClass]="{'team-button-selected': prediction.winSelection===3, 'team-win-button-selected': prediction.winSelection===3 && prediction.winSelectionHit===1 && showResults, 'team-lose-button-selected': prediction.winSelection===3 && prediction.winSelectionHit===0 && showResults}"
                                mat-stroked-button
                                fxFlex
                                [disabled]="showResults"
                                (click)="teamClick(3)">
                            {{prediction.sportTeamIdAwayTeamName}}
                        </button>
                    </div>
                    <button mat-stroked-button
                            *ngIf="!prediction.winSelection"
                            class="goal-button"
                            style="color: gray !important;"
                            disabled>
                        {{'GOAL_DIFFERENCE' | translate }}
                    </button>
                    <div *ngIf="prediction.winSelection === 1 || prediction.winSelection === 3"
                         fxLayout="row">
                        <button class="goal-button"
                                [ngClass]="{'goal-button-selected': prediction.goalsSelection===1, 'boost2-button': boost2, 'goal-win-button-selected': prediction.goalsSelection===1 && prediction.goalsSelectionHit===1 && showResults,'goal-lose-button-selected': prediction.goalsSelection===1 && prediction.goalsSelectionHit===0 && showResults}"
                                fxFlex
                                mat-stroked-button
                                [disabled]="showResults"
                                (click)="goalClick(1)">
                            1 Mark
                        </button>
                        <button class="goal-button"
                                [ngClass]="{'goal-button-selected': prediction.goalsSelection===2, 'boost2-button': boost2, 'goal-win-button-selected': prediction.goalsSelection===2 && prediction.goalsSelectionHit===1 && showResults,'goal-lose-button-selected': prediction.goalsSelection===2 && prediction.goalsSelectionHit===0 && showResults}"
                                fxFlex
                                mat-stroked-button
                                [disabled]="showResults"
                                (click)="goalClick(2)">
                            2 Mörk
                        </button>
                        <button class="goal-button"
                                [ngClass]="{'goal-button-selected': prediction.goalsSelection===3, 'boost2-button': boost2, 'goal-win-button-selected': prediction.goalsSelection===3 && prediction.goalsSelectionHit===1 && showResults,'goal-lose-button-selected': prediction.goalsSelection===3 && prediction.goalsSelectionHit===0 && showResults}"
                                fxFlex
                                mat-stroked-button
                                [disabled]="showResults"
                                (click)="goalClick(3)">
                            3+ Mörk
                        </button>
                    </div>
                    <div *ngIf="prediction.winSelection === 2"
                         fxLayout="row">
                        <button class="goal-button"
                                [ngClass]="{'goal-button-selected': prediction.goalsSelection===1, 'boost2-button': boost2, 'goal-win-button-selected': prediction.goalsSelection===1 && prediction.goalsSelectionHit===1 && showResults,'goal-lose-button-selected': prediction.goalsSelection===1 && prediction.goalsSelectionHit===0 && showResults}"
                                fxFlex
                                mat-stroked-button
                                [disabled]="showResults"
                                (click)="goalClick(1)">
                            0-0
                        </button>
                        <button class="goal-button"
                                [ngClass]="{'goal-button-selected': prediction.goalsSelection===2, 'boost2-button': boost2, 'goal-win-button-selected': prediction.goalsSelection===2 && prediction.goalsSelectionHit===1 && showResults,'goal-lose-button-selected': prediction.goalsSelection===2 && prediction.goalsSelectionHit===0 && showResults}"
                                fxFlex
                                mat-stroked-button
                                [disabled]="showResults"
                                (click)="goalClick(2)">
                            1-1
                        </button>
                        <button class="goal-button"
                                [ngClass]="{'goal-button-selected': prediction.goalsSelection===3, 'boost2-button': boost2, 'goal-win-button-selected': prediction.goalsSelection===3 && prediction.goalsSelectionHit===1 && showResults,'goal-lose-button-selected': prediction.goalsSelection===3 && prediction.goalsSelectionHit===0 && showResults}"
                                fxFlex
                                mat-stroked-button
                                [disabled]="showResults"
                                (click)="goalClick(3)">
                            2-2+
                        </button>
                    </div>
                    <div fxLayout="row">
                        <button *ngIf="!showResults"
                                class="bet-button"
                                fxFlex
                                mat-stroked-button
                                (click)="showProbabilityClick()">
                            {{'PROBABILITIES' | translate | uppercase }}
                            <mat-icon>arrow_drop_up</mat-icon>
                        </button>

                        <button *ngIf="showResults"
                                class="bet-button"
                                mat-icon-button
                                fxFlex
                                mat-stroked-button
                                disableRipple>
                            <div fxLayout="row"
                                 fxLayoutAlign="space-between center"
                                 style="padding: 0 8px;">
                                <mat-icon></mat-icon>
                                <span>{{prediction.fantasyPoints}} pt</span>
                                <mat-icon *ngIf="prediction.matchClosed">done_all</mat-icon>
                                <mat-icon *ngIf="!prediction.matchClosed"></mat-icon>
                            </div>
                        </button>
                    </div>
                </div>
            </ng-template>
        </div>

        <div fxFlex
             class="jersey-away pointer"
             (click)="showTeam(prediction.sportTeamIdAwayTeam)">
            <img [src]="prediction.sportTeamIdAwayTeamKit"
                 width="100%">
        </div>
    </div>
</div>