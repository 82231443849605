import { Injectable } from '@angular/core';
import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

import { AuthService } from 'src/app/auth/auth.service';

@Injectable({
    providedIn: 'root'
})
export class HttpErrorInterceptor implements HttpInterceptor {

    constructor(
        private toastr: ToastrService,
        private authService: AuthService
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    let errorMessage = '';
                    if (error.error instanceof ErrorEvent) {
                        // client-side error
                        errorMessage = `Error: ${error.error.message}`;
                        this.toastr.error(error.error.message);
                    } else {
                        // server-side error
                        this.toastr.error(error.error.message);
                        errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;

                        if (error instanceof HttpErrorResponse) {
                            if (error.status === 401 && this.authService.token) {
                                this.authService.logout();
                            }
                        }
                    }
                    return throwError(errorMessage);
                })
            );
    }
}
