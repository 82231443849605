import { Component, OnInit } from '@angular/core';
import { DataManagerService } from 'src/app/core/services/data-manager.service';
import { Ticket } from 'src/app/shared/models/ticket';
import { Standing } from 'src/app/shared/models/standing';
import { Sponsors } from 'src/app/shared/models/sponsor';
import { MatDialog } from '@angular/material/dialog';
import { TicketWarningComponent } from './ticket-warning/ticket-warning.component';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-ticket',
  templateUrl: './ticket.component.html',
  styleUrls: ['./ticket.component.scss']
})
export class TicketComponent implements OnInit {

  public columns = ['rank', 'name', 'played', 'win', 'draw', 'loss', 'goalsFor', 'goalsAgainst', 'points'];
  public ticket: Ticket = {} as Ticket;
  public standings: Standing[];
  public sponsors: Sponsors;
  public qouta = 0;
  public qoutaMultiplier = 200;
  public total = 0;

  constructor(
    public dataManager: DataManagerService,
    public dialog: MatDialog,
    private toastr: ToastrService,
  ) {
    this.getTicket();
  }

  ngOnInit() {
  }

  getTicket(gameWeeksId?) {
    this.dataManager.getTicket(gameWeeksId).subscribe(ticket => {
      this.ticket = ticket;
      this.calculateQuota();
      this.getSponsors(this.ticket.gameWeeksId);
    });
    this.dataManager.getStandings().subscribe(standings => this.standings = standings);
  }

  getSponsors(gameWeeksId) {
    this.dataManager.getSponsors(gameWeeksId).subscribe(sponsors => this.sponsors = sponsors);
  }

  boost1Click() {
    if (this.ticket.boost1Used === false) {
      this.ticket.boost1 = !this.ticket.boost1;
      this.ticket.boost2 = false;
      this.ticket.predictions.forEach(prediction => {
        prediction.matchStar = this.ticket.boost1;
      });
    }
  }

  boost2Click() {
    if (this.ticket.boost2Used === false) {
      this.ticket.boost1 = false;
      this.ticket.boost2 = !this.ticket.boost2;
      this.resetCrowns();
    }
  }

  gamesNext() {
    this.getTicket(this.ticket.gameWeekNext);
  }

  gamesPrevious() {
    this.getTicket(this.ticket.gameWeekPrevious);
  }

  resetCrowns() {
    this.ticket.predictions.forEach(prediction => prediction.matchStar = false);
    this.ticket.boost1 = false;
  }

  randomize() {
    this.ticket.predictions.forEach(prediction => {
      prediction.winSelection = this.getRandomInt();
      prediction.goalsSelection = this.getRandomInt();
    });

    this.resetCrowns();
    this.ticket.predictions[this.getRandomInt(0, this.ticket.predictions.length)].matchStar = true;
  }

  private getRandomInt(min = 1, max = 4) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min)) + min;
  }

  submit() {
    if (this.ticket.boost1 || this.ticket.boost2) {
      this.dialog
        .open(TicketWarningComponent, {
          data: this.ticket.boost1 ? 1 : 2,
          width: '96%', maxWidth: '600px',
        })
        .afterClosed()
        .subscribe(res => {
          if (res) {
            this.dataManager.postTicket(this.ticket)
              .subscribe(() => {
                this.getTicket(this.ticket.gameWeeksId);
                this.toastr.show('Your ticket has been changed successfully! ');
              });
          }
        });
    } else {
      this.dataManager.postTicket(this.ticket)
        .subscribe(() => {
          this.getTicket(this.ticket.gameWeeksId);
          this.toastr.show('Your ticket has been changed successfully! ');
        });
    }
  }

  goToLink(url: string) {
    window.open(url);
  }

  calculateQuota() {
    this.qouta = 0;
    this.ticket.predictions.forEach(prediction => {
      if (prediction.winSelection) {
        const data = Object.values(prediction.probability.probability);
        const value = Number(data[prediction.winSelection - 1]);
        if (value > 0) {
          if (this.qouta === 0) {
            this.qouta = 1;
          }
          this.qouta = this.qouta * value;
        }
      }
    });
    this.qouta = Number(this.qouta.toFixed(2));
    this.total = Number((this.qoutaMultiplier * this.qouta).toFixed(2));
  }

  Tippa() {
    this.dataManager.postlogtippa().subscribe();
    this.goToLink('https://games.lotto.is/game/lengjan?live=0&competition=746');
  }

}
