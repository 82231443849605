import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';

import { DataManagerService } from 'src/app/core/services/data-manager.service';
import { AuthService } from '../auth.service';
import { MustMatch } from 'src/app/shared/validator/must-match.validator';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  public maxDate = new Date(2004, 0, 31);
  public submitted = false;

  public formGroup = this.fb.group({
    name: ['', Validators.required],
    email: ['', Validators.required],
    dob: ['', Validators.required],
    idCountry: [99, Validators.required],
    password: ['', Validators.required],
    confirmPassword: ['', Validators.required],
  }, {
    validator: MustMatch('password', 'confirmPassword')
  });

  constructor(
    private fb: FormBuilder,
    public dataManager: DataManagerService,
    private authService: AuthService,
    private router: Router,
  ) { }

  ngOnInit(): void {
  }

  submit() {
    this.submitted = true;
    if (this.formGroup.valid) {
      const registartionData = { ...this.formGroup.value };
      registartionData.dob = registartionData.dob.getTime();
      this.dataManager.register(registartionData)
        .pipe(
          switchMap(res =>
            this.authService.login({ username: this.formGroup.value.email, password: this.formGroup.value.password }))
        )
        .subscribe(
          (res) => {
            this.router.navigate(['agreement']);
          }
        );
    }
  }


}
