import { Component, OnInit } from '@angular/core';
import { LeaderboardItem } from 'src/app/shared/models/leaderboardItem';
import { DataManagerService } from 'src/app/core/services/data-manager.service';
import { Gameweek } from 'src/app/shared/models/gameweek';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-leaderboard',
  templateUrl: './leaderboard.component.html',
  styleUrls: ['./leaderboard.component.scss']
})
export class LeaderboardComponent implements OnInit {

  time = new Date();
  public leaderboardItems: LeaderboardItem[] = [];
  public gameweeks: Gameweek[] = [];
  public length = 1;
  public pageIndex = 0;
  public pageSize = 25;
  public gameweekId: number;

  constructor(
    public dataManager: DataManagerService,
  ) {
    this.getLeaderboard();
    this.getGameweeks();
  }

  ngOnInit(): void {
    setInterval(() => {
      this.time = new Date();
    }, 1000);
  }

  getLeaderboard(gameweekId?) {

    if (gameweekId) {
      this.gameweekId = gameweekId;
    }

    this.dataManager.getLeaderboard(this.gameweekId, this.pageIndex, this.pageSize)
      .subscribe(leaderboardItems => {
        this.leaderboardItems = leaderboardItems.data;
        this.length = leaderboardItems.length;
        this.pageIndex = leaderboardItems.pageIndex;
        this.pageSize = leaderboardItems.pageSize;
      });
  }

  changePage(page: PageEvent) {
    this.pageSize = page.pageSize;
    this.pageIndex = page.pageIndex;
    this.getLeaderboard();
  }

  getGameweeks() {
    this.dataManager.getGameweeks().subscribe(gameweeks => {
      this.gameweeks = gameweeks;
    });
  }

}
