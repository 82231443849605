import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { DataManagerService } from 'src/app/core/services/data-manager.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-team-name',
  templateUrl: './team-name.component.html',
  styleUrls: ['./team-name.component.scss'],
})
export class TeamNameComponent implements OnInit {

  public submitted = false;
  public showSpinner = false;

  public formGroup = this.fb.group({
    teamName: ['', Validators.required],
  });

  constructor(
    private fb: FormBuilder,
    public dataManager: DataManagerService,
    private router: Router,
  ) { }

  ngOnInit(): void {
  }

  submit() {
    this.submitted = true;
    if (this.formGroup.valid) {
      this.dataManager.postMe(undefined, true, this.formGroup.value.teamName)
        .subscribe(
          (data) => {
            this.router.navigate(['main']);
          }, e => {
            this.showSpinner = false;
          }
        );
    }
  }
}


