<div fxLayout="row"
     fxLayoutAlign="space-around center"
     class="container-bg">
    <div></div>
    <span class="primary">
        <b>{{authService.user.name}}</b>
    </span>
    <div style="width: 2px; height: 50%; "
         class="primary-bg"></div>
    <span class="primary">
        <b>{{authService.user.points}} {{'PT' | translate }}</b>
    </span>
    <div style="width: 2px; height: 50%; "
         class="primary-bg"></div>
    <span class="primary">
        <b>{{authService.user.ranked}} {{'PLACE' | translate }}</b>
    </span>
    <div></div>
</div>