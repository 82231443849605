import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WinnersComponent } from './winners.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { CoreModule } from '@angular/flex-layout';



@NgModule({
  declarations: [WinnersComponent],
  imports: [
    CommonModule,
    CoreModule,
    SharedModule,
  ]
})
export class WinnersModule { }
