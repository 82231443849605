<app-score></app-score>
<div class="content">
    <div class="mat-elevation-z8">
        <div fxLayout="row"
             style="padding: 12px; margin-top: 2%;">
            <mat-form-field class="fantasy-select">
                <mat-select [value]="''">
                    <mat-option [value]="''"
                                (click)="getLeaderboard()">
                        {{'OVERALL' | translate }}
                    </mat-option>
                    <mat-option *ngFor="let gameweek of gameweeks"
                                [value]="gameweek.id"
                                (click)="getLeaderboard(gameweek.id)">
                        {{gameweek.gameweekTitle}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div style="padding: 8px;">
            <div fxLayout="column"
                 style="background-color: white; margin-bottom: 13%;">
                <div class="primary-bg"
                     style="height: 60px; padding: 8px;">
                    <div fxLayoutAlign="center center"
                         style="margin-top: 3%;">
                        <h2>{{'LEADERBOARD' | translate }}</h2>
                    </div>
                </div>
                <app-leaderboard-list [leaderboardItems]="leaderboardItems">
                </app-leaderboard-list>
                <mat-paginator [length]="length"
                               [pageSize]="pageSize"
                               [pageIndex]="pageIndex"
                               [hidePageSize]="true"
                               (page)="changePage($event)">
                </mat-paginator>
            </div>
        </div>
    </div>
</div>