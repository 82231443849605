import { Component, OnInit } from '@angular/core';
import { DataManagerService } from 'src/app/core/services/data-manager.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-agreement',
  templateUrl: './agreement.component.html',
  styleUrls: ['./agreement.component.scss']
})
export class AgreementComponent implements OnInit {

  constructor(
    public dataManager: DataManagerService,
    private router: Router,
  ) { }

  ngOnInit(): void {
  }

  agreeTerms() {
    this.dataManager.postMe(undefined, true, undefined)
      .subscribe(
        data => {
          if (!data.teamName) {
            this.router.navigate(['team-name']);
          }
        });
  }

}
