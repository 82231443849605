<form [formGroup]="formGroup">
    <app-input-container>
        <input fxFlex
               type="text"
               placeholder="{{'EMAIL' | translate }}"
               formControlName="username"
               autocomplete="email"
               required>
        <mat-error *ngIf="submitted && formGroup.get('username').hasError('required')">
            {{'EMAIL_IS_REQUIRED' | translate }}
        </mat-error>
    </app-input-container>

    <app-input-container>
        <input fxFlex
               type="password"
               placeholder="{{'PASSWORD' | translate }}"
               formControlName="password"
               required>
        <mat-error *ngIf="submitted && formGroup.get('password').hasError('required')">
            {{'PASSWORD_IS_REQUIRED' | translate }}
        </mat-error>
    </app-input-container>
</form>

<div fxLayout="row"
     fxLayoutAlign="center center">
    <button mat-button
            [matMenuTriggerFor]="language"
            class="language-button">
        Language
        <mat-icon>expand_more</mat-icon>
    </button>
</div>
<mat-menu #language="matMenu">
    <button mat-menu-item
            *ngFor="let language of languages"
            (click)="changeLanguage(language.value)">
        {{ language.displayText }}
    </button>
</mat-menu>

<div fxLayout="column"
     fxLayoutGap="8px">
    <button mat-raised-button
            color="warn"
            (click)="submit()">
        {{'LOG_IN' | translate }}
    </button>

    <button mat-raised-button
            routerLink="/register">
        {{'SIGN_UP' | translate }}
    </button>
    <button mat-button
            routerLink="/forgot-password">
        {{'FORGOT_YOUR_PASSWORD' | translate }}
    </button>
</div>